import React, { useState, useEffect } from 'react';
import { Col, Row, Table, Button } from 'reactstrap';
import AuthRequired from '../components/AuthRequired';
import { getDxReports } from '../queries';
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Select from 'react-select';
import Pagination from '../components/Pagination';
import { displayError } from '../utilities';

const itemsPerPage = 10;

const DxApp = () => {
  const specialities = [
    { label: 'All', value: 0 },
    { label: 'Pediatrics', value: 1 },
    { label: 'Obstetrics & Gynecology', value: 2 },
    { label: 'Fertility', value: 3 }
  ];

  const [fromDate, setFromDate] = useState(
    moment()
      .startOf('day')
      .toDate()
  );
  const [toDate, setToDate] = useState(
    moment()
      .endOf('day')
      .toDate()
  );
  const [reports, setReports] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState(specialities[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [adoptionPercentage, setAdoptionPercentage] = useState(0);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchReports(fromDate, toDate);
    // eslint-disable-next-line
  }, [selectedSpeciality]);

  const fetchReports = (fromDate, toDate) => {
    setLoading(true);
    const hospitalId = parseInt(localStorage.getItem('DEFAULT_HOSPITAL_ID'));
    const startDate = moment(fromDate)
      .startOf('day')
      .toDate();
    let endDate = moment(toDate)
      .endOf('day')
      .toDate();
    const selectedRange = moment
      .duration(moment(endDate).diff(moment(startDate)))
      .asDays();

    if (selectedRange > 30) {
      setErrorMessage('Date range cannot exceed 30 days.');
      endDate = moment(startDate)
        .add(30, 'days')
        .toDate();
      setToDate(endDate);
    } else {
      setErrorMessage('');
    }
    let departmentId = null;

    if (selectedSpeciality.value !== 0) {
      departmentId = selectedSpeciality.value;
    }

    getDxReports(hospitalId, startDate, endDate, departmentId)
      .then(data => {
        if (data && data.getDxReports) {
          setReports(data.getDxReports.data);
          setAdoptionPercentage(data.getDxReports.adoptionPercentage);
          setErrorMessage('');
        } else {
          setReports([]);
          setAdoptionPercentage(0);
          setErrorMessage('');
        }
      })
      .catch(error => {
        displayError('Something went wrong', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const downloadExcel = () => {
    const columnOrder = [
      'Date',
      "Patient's Name",
      'Contact Number',
      'MPID',
      "Consultant's Name",
      'Lab Test',
      'Scan',
      'Medicines',
      'Vaccines',
      'Follow up date',
      'Prescription Link'
    ];

    const data = reports.map(report => ({
      Date: moment(report.booking.slotTime).format('MM/DD/YYYY'),
      "Patient's Name": report.booking.userName,
      'Contact Number': report.booking.userMobile,
      MPID: report.booking.mpid,
      "Consultant's Name": report.booking.objectName,
      'Lab Test': report.labs
        ? report.labs.map(lab => lab.name).join(', ')
        : '',
      Scan: report.scans ? report.scans.map(scan => scan.name).join(', ') : '',
      Medicines: report.medicines
        ? report.medicines.map(medicine => medicine.name).join(', ')
        : '',
      Vaccines: report.administeredVaccines
        ? report.administeredVaccines
            .map(vaccine => vaccine.vaccine.name)
            .join(', ')
        : '',
      'Follow up date': report.followupDate || '',
      'Prescription Link': report.prescriptionLink || ''
    }));

    const worksheet = XLSX.utils.json_to_sheet(data, { header: columnOrder });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Reports');
    XLSX.writeFile(workbook, 'dxreports.xlsx');
  };

  return (
    <AuthRequired pageName="dxapp">
      <div style={{ margin: '1rem' }}>
        <Row>
          <Col>
            <h1>DxApp Dashboard</h1>
          </Col>
        </Row>
        <Row className="toolbar">
          <Col>
            <label className="bold">Speciality:</label>
            <Select
              value={selectedSpeciality}
              placeholder="Select speciality"
              options={specialities}
              onChange={v => setSelectedSpeciality(v)}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
            />
          </Col>
          <Col className="adoption-percentage-container">
            <label className="adoption-percentage">Adoption Percentage:</label>
            <div style={{ marginLeft: '5px', position: 'relative' }}>
              <div className="battery-container">
                <div
                  className="battery-fill"
                  style={{ height: `${adoptionPercentage}%` }}
                ></div>
              </div>
              <div>{adoptionPercentage}%</div>
            </div>
          </Col>
        </Row>
        <Row className="toolbar">
          <Col sm={3}>
            <label className="bold">From Date:</label>
            <DatePicker
              selected={moment(fromDate)}
              maxDate={moment()}
              dateFormat="DD MMM YYYY"
              selectsStart
              startDate={moment(fromDate)}
              endDate={moment(toDate)}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              onChange={date => setFromDate(date)}
              className="reports-date-height"
            />
          </Col>
          <Col sm={3}>
            <label className="bold">To Date:</label>
            <div style={{ position: 'relative' }}>
              <DatePicker
                selected={moment(toDate)}
                maxDate={moment()}
                dateFormat="DD MMM YYYY"
                selectsStart
                startDate={moment(fromDate)}
                endDate={moment(toDate)}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={date => setToDate(date)}
                className="reports-date-height"
              />
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
          </Col>
          <Col>
            <Button
              style={{ marginTop: '1.8rem' }}
              color="primary"
              onClick={() => fetchReports(fromDate, toDate)}
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Get Reports'}
            </Button>
          </Col>
        </Row>
        <Row className="toolbar">
          <Col style={{ marginTop: '1rem' }}>
            <Table size="sm" striped responsive>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Date</th>
                  <th>Patient&apos;s Name</th>
                  <th>Contact Number</th>
                  <th>MPID</th>
                  <th>Consultant&apos;s Name</th>
                  <th>Lab Test</th>
                  <th>Scan</th>
                  <th>Medicines</th>
                  <th>Vaccines</th>
                  <th>Follow up date</th>
                  <th>Prescription Link</th>
                </tr>
              </thead>
              <tbody>
                {reports.length === 0 ? (
                  <tr>
                    <td colSpan="20" className="text-center bold">
                      No data available
                    </td>
                  </tr>
                ) : (
                  reports.slice(startIndex, endIndex).map((report, index) => (
                    <tr key={startIndex + index}>
                      <td>{startIndex + index + 1}</td>
                      <td>
                        {moment(report.booking.slotTime).format('MM/DD/YYYY')}
                      </td>
                      <td>{report.booking.userName}</td>
                      <td>{report.booking.userMobile}</td>
                      <td>{report.booking.mpid}</td>
                      <td>{report.booking.objectName}</td>
                      <td>
                        {report.labs &&
                          report.labs.map((lab, labIndex) => (
                            <div key={labIndex}>{lab.name}</div>
                          ))}
                      </td>
                      <td>
                        {report.scans &&
                          report.scans.map((scan, scanIndex) => (
                            <div key={scanIndex}>{scan.name}</div>
                          ))}
                      </td>
                      <td>
                        {report.medicines &&
                          report.medicines.map((medicine, medicineIndex) => (
                            <div key={medicineIndex}>{medicine.name}</div>
                          ))}
                      </td>
                      <td>
                        {report.administeredVaccines &&
                          report.administeredVaccines.map(
                            (vaccine, vaccineIndex) => (
                              <div key={vaccineIndex}>
                                {vaccine.vaccine.name}
                              </div>
                            )
                          )}
                      </td>
                      <td>{report.followupDate}</td>
                      <td>
                        {report.prescriptionLink && (
                          <a
                            href={`${report.prescriptionLink}?token=${token}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Open
                          </a>
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        {reports.length > 0 && (
          <Row className="toolbar">
            <Col className="pagination-container">
              <Pagination
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
                totalPages={Math.ceil(reports.length / itemsPerPage)}
              />
            </Col>
            <Col xs="auto" className="download-button">
              <Button color="success" onClick={downloadExcel}>
                Download Report
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </AuthRequired>
  );
};

export default DxApp;
