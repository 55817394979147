import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button
} from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import Loader from '../components/Loader';
import {
  getLabHistory,
  getPrescriptionHistoryDetails,
  getScanHistory,
  getUploadedFiles
} from '../queries';

const TABS = {
  PRESCRIPTIONS: 1,
  LAB_REPORTS: 2,
  SCAN_REPORTS: 3,
  UPLOADED_FILES: 4
};

const CustomerRxModal = ({ userMobile, userMPID, isOpen, toggle }) => {
  const [selectedTab, setSelectedTab] = useState(TABS.PRESCRIPTIONS);
  const [pastPrescriptionHistory, setPastPrescriptionHistory] = useState([]);
  const [labReportHistory, setLabReportHistory] = useState([]);
  const [scanReportHistory, setScanReportHistory] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loaderIcon, setLoaderIcon] = useState(true);
  const [buttonLoaderIcon, setButtonLoaderIcon] = useState(false);
  const [indexValue, setIndexValue] = useState(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    getAppointmentHistory(userMPID);
    getLabReportHistory(userMPID);
    getScanReportHistory(userMPID);
    getUploadedFilesDetails(userMPID);
    setSelectedTab(TABS.PRESCRIPTIONS);
    setPastPrescriptionHistory([]);
    setLabReportHistory([]);
    setScanReportHistory([]);
    setUploadedFiles([]);
    setLoaderIcon(true);
    setButtonLoaderIcon(false);
    setIndexValue(null);
    // eslint-disable-next-line
  }, [userMobile, userMPID]);

  // function to handle view prescription button press
  const handleOnPressViewPrescription = prescriptionLink => {
    const pdfUrl = `${prescriptionLink}?token=${token}`;
    window.open(pdfUrl, '_blank');
  };

  // function to fetch and sort all appointments based on appointment date and store this information in state to show it to the users.
  const getAppointmentHistory = async userMPID => {
    setLoaderIcon(true);
    try {
      const response = await getPrescriptionHistoryDetails(userMPID);
      if (response && response.data && response.data.getPrescriptionHistoryV2) {
        const allPastAppointments = response.data.getPrescriptionHistoryV2.filter(
          appointment => appointment.prescriptionLink
        );
        setPastPrescriptionHistory(allPastAppointments);
        setLoaderIcon(false);
      } else {
        setLoaderIcon(false);
      }
    } catch (error) {
      setLoaderIcon(false);
    }
  };

  // function to handle view lab report button press.
  const handleOnPressLabReport = url => {
    const pdfUrl = `${url}?token=${token}`;
    window.open(pdfUrl, '_blank');
  };

  // function to fetch all lab reports
  const getLabReportHistory = async mpid => {
    setLoaderIcon(true);
    try {
      const response = await getLabHistory(mpid);
      if (response && response.data && response.data.getLabHistory) {
        const allLabReports = response.data.getLabHistory.filter(
          lab => lab.url
        );
        setLabReportHistory(allLabReports);
        setLoaderIcon(false);
      } else {
        setLoaderIcon(false);
      }
    } catch (error) {
      setLoaderIcon(false);
    }
  };

  // function to handle view scan report button press.
  const handleOnPressScanReport = updatedPdflink => {
    const pdfUrl = `${updatedPdflink}?token=${token}`;
    window.open(pdfUrl, '_blank');
  };

  // function to fetch and sort all scan reports based on visit date and store this information in state to show it to the users.
  const getScanReportHistory = async mpid => {
    setLoaderIcon(true);
    try {
      const response = await getScanHistory(mpid);
      if (response && response.data && response.data.getScanHistory) {
        const allScanReports = response.data.getScanHistory.sort(
          (a, b) => b.momenttimestamp - a.momenttimestamp
        );
        setScanReportHistory(allScanReports);
        setLoaderIcon(false);
      } else {
        setLoaderIcon(false);
      }
    } catch (error) {
      setLoaderIcon(false);
    }
  };

  // function to handle view uploaded file button press.
  const handleOnPressUploadedFile = s3filelink => {
    const pdfUrl = `${s3filelink}?token=${token}`;
    window.open(pdfUrl, '_blank');
  };

  // function to fetch and sort all uploaded files based on updated at date and store this information in state to show it to the users.
  const getUploadedFilesDetails = async mpid => {
    setLoaderIcon(true);
    try {
      const response = await getUploadedFiles(mpid);
      if (response && response.data && response.data.getUploadedFiles) {
        const allUploadedFiles = response.data.getUploadedFiles;
        setUploadedFiles(allUploadedFiles);
        setLoaderIcon(false);
      } else {
        setLoaderIcon(false);
      }
    } catch (error) {
      setLoaderIcon(false);
    }
  };

  return (
    <div>
      <Modal scrollable={true} size="lg" isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Patient&#39;s Prescription Details
        </ModalHeader>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: selectedTab === TABS.PRESCRIPTIONS
                })}
                onClick={() => {
                  setSelectedTab(TABS.PRESCRIPTIONS);
                }}
              >
                PRESCRIPTIONS
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: selectedTab === TABS.LAB_REPORTS
                })}
                onClick={() => {
                  setSelectedTab(TABS.LAB_REPORTS);
                }}
              >
                LAB REPORTS
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: selectedTab === TABS.SCAN_REPORTS
                })}
                onClick={() => {
                  setSelectedTab(TABS.SCAN_REPORTS);
                }}
              >
                SCAN REPORTS
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: selectedTab === TABS.UPLOADED_FILES
                })}
                onClick={() => {
                  setSelectedTab(TABS.UPLOADED_FILES);
                }}
              >
                UPLOADED FILES
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={selectedTab}>
            <TabPane tabId={TABS.PRESCRIPTIONS}>
              {loaderIcon ? (
                <Loader />
              ) : (
                selectedTab === TABS.PRESCRIPTIONS &&
                (pastPrescriptionHistory.length ? (
                  pastPrescriptionHistory.map((appointment, index) => {
                    const {
                      doctorName,
                      slotTime,
                      prescriptionLink
                    } = appointment;
                    return (
                      <div key={index}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 13
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              width: '50%',
                              flexDirection: 'column'
                            }}
                          >
                            {doctorName ? <div>{doctorName}</div> : null}
                            {slotTime ? (
                              <div>{moment(slotTime).format('DD/MM/YYYY')}</div>
                            ) : null}
                          </div>
                          <div style={{ width: '50%', textAlign: 'right' }}>
                            {buttonLoaderIcon && index === indexValue ? (
                              <div style={{ marginRight: 60 }}>
                                <Loader size={5} />
                              </div>
                            ) : (
                              <Button
                                size="sm"
                                className="patient-badge-action-button"
                                color="primary"
                                title="VIEW PRESCRIPTION"
                                style={{ textAlign: 'right' }}
                                onClick={() => {
                                  handleOnPressViewPrescription(
                                    prescriptionLink
                                  );
                                }}
                              >
                                VIEW PRESCRIPTION
                              </Button>
                            )}
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 300,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 13
                    }}
                  >
                    <p>No Prescription Available</p>
                  </div>
                ))
              )}
            </TabPane>

            <TabPane tabId={TABS.LAB_REPORTS}>
              {loaderIcon ? (
                <Loader />
              ) : (
                selectedTab === TABS.LAB_REPORTS &&
                (labReportHistory.length ? (
                  labReportHistory.map((report, index) => {
                    const { accessioned_at, item_name, url } = report;
                    return (
                      <div key={index}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 13
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              width: '50%',
                              flexDirection: 'column'
                            }}
                          >
                            {item_name ? <div>{item_name}</div> : null}
                            {accessioned_at ? (
                              <div>Sample Collected on - {accessioned_at}</div>
                            ) : null}
                          </div>
                          <div style={{ width: '50%', textAlign: 'right' }}>
                            {buttonLoaderIcon && index === indexValue ? (
                              <div style={{ marginRight: 60 }}>
                                <Loader size={5} />
                              </div>
                            ) : (
                              <Button
                                size="sm"
                                className="patient-badge-action-button"
                                color="primary"
                                title="VIEW REPORT"
                                style={{ textAlign: 'right' }}
                                onClick={() => {
                                  handleOnPressLabReport(url);
                                }}
                              >
                                VIEW REPORT
                              </Button>
                            )}
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 300,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 13
                    }}
                  >
                    <p>No Lab Report Available</p>
                  </div>
                ))
              )}
            </TabPane>

            <TabPane tabId={TABS.SCAN_REPORTS}>
              {loaderIcon ? (
                <Loader />
              ) : (
                selectedTab === TABS.SCAN_REPORTS &&
                (scanReportHistory.length ? (
                  scanReportHistory.map((report, index) => {
                    const { VisitDate, ScanDoneBy, updatedPdflink } = report;
                    return (
                      <div key={index}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 13
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              width: '50%',
                              flexDirection: 'column'
                            }}
                          >
                            {VisitDate ? (
                              <div>Done on - {VisitDate}</div>
                            ) : null}
                            {ScanDoneBy ? <div>by {ScanDoneBy}</div> : null}
                          </div>
                          <div style={{ width: '50%', textAlign: 'right' }}>
                            {buttonLoaderIcon && index === indexValue ? (
                              <div style={{ marginRight: 60 }}>
                                <Loader size={5} />
                              </div>
                            ) : (
                              <Button
                                size="sm"
                                className="patient-badge-action-button"
                                color="primary"
                                title="VIEW REPORT"
                                style={{ textAlign: 'right' }}
                                onClick={() => {
                                  handleOnPressScanReport(updatedPdflink);
                                }}
                              >
                                VIEW REPORT
                              </Button>
                            )}
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 300,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 13
                    }}
                  >
                    <p>No Scan Report Available</p>
                  </div>
                ))
              )}
            </TabPane>

            <TabPane tabId={TABS.UPLOADED_FILES}>
              {loaderIcon ? (
                <Loader />
              ) : (
                selectedTab === TABS.UPLOADED_FILES &&
                (uploadedFiles.length ? (
                  uploadedFiles.map((file, index) => {
                    const { updated_at, filename, s3filelink } = file;
                    return (
                      <div key={index}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 13
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              width: '50%',
                              flexDirection: 'column'
                            }}
                          >
                            {filename ? <div>{filename}</div> : null}
                            {updated_at ? (
                              <div>
                                {moment(updated_at).format('DD/MM/YYYY HH:mm')}
                              </div>
                            ) : null}
                          </div>
                          <div style={{ width: '50%', textAlign: 'right' }}>
                            {buttonLoaderIcon && index === indexValue ? (
                              <div style={{ marginRight: 60 }}>
                                <Loader size={5} />
                              </div>
                            ) : (
                              <Button
                                size="sm"
                                className="patient-badge-action-button"
                                color="primary"
                                title="VIEW FILE"
                                style={{ textAlign: 'right' }}
                                onClick={() => {
                                  handleOnPressUploadedFile(s3filelink);
                                }}
                              >
                                VIEW FILE
                              </Button>
                            )}
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 300,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 13
                    }}
                  >
                    <p>No Uploaded File Available</p>
                  </div>
                ))
              )}
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    </div>
  );
};

CustomerRxModal.propTypes = {
  userMobile: PropTypes.string.isRequired,
  userMPID: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default CustomerRxModal;
