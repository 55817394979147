import amplitude from 'amplitude-js';
import React from 'react';
import { FaRegCheckSquare, FaRegTimesCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { GQL_ENDPOINT, COGNITO_API_KEY, COGNITO_BASE_URL } from './constants';
import moment from 'moment';

const toastError = (str, autoHide = true) => {
  const conf = {};
  if (autoHide) {
    conf.autoClose = 3000;
  }
  toast.error(
    <span style={{ color: 'white' }}>
      <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
        <FaRegTimesCircle />
      </span>
      {` ${str}`}
    </span>,
    conf
  );
};

export const displayError = errorObjOrString => {
  if (typeof errorObjOrString === 'string') {
    toastError(errorObjOrString);
    return;
  }
  const { graphQLErrors, networkError, message } = errorObjOrString;
  let displayMessage = message;
  if (graphQLErrors && graphQLErrors.length > 0) {
    displayMessage = graphQLErrors[0].message;
  } else if (networkError) {
    displayMessage = networkError.message;
  }
  toastError(displayMessage);
};

export const getGraphQLErrString = ({ graphQLErrors, networkError, message }) =>
  graphQLErrors && graphQLErrors.length > 0
    ? graphQLErrors[0].message
    : networkError
    ? networkError.message
    : message;

export const displaySuccess = (str, autoHide = true) => {
  const conf = {};
  if (autoHide) {
    conf.autoClose = 3000;
  }
  toast.success(
    <span style={{ color: 'white' }}>
      <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
        <FaRegCheckSquare />
      </span>
      {` ${str}`}
    </span>,
    conf
  );
};
export const displayInfo = str => toast.info(str);

export const getFullName = (firstName, lastName) => {
  const fname = firstName || '';
  const lname = lastName || '';
  return `${fname} ${lname}`.trim();
};

export const isImageBigEnough = file =>
  new Promise(resolve => {
    if (!file) {
      return false;
    }
    //eslint-disable-next-line
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function(e) {
      //eslint-disable-next-line
      const image = new Image();
      image.src = e.target.result;
      image.onload = function() {
        const height = this.height;
        const width = this.width;
        // console.log(`h: ${height} w: ${width}`);
        if (height < 500 || width < 500) {
          resolve(false);
          return;
        }
        resolve(true);
        return;
      };
    };
  });

export const getTransformedImagesFromCloudinaryResponse = json => {
  const dp300 = {};
  const eager0 = json.eager[0];
  dp300.url = eager0.secure_url;
  dp300.height = eager0.height;
  dp300.width = eager0.width;

  return { dp300 };
};

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isValidEmail = email => {
  return EMAIL_REGEX.test(email);
};

export const setDefaultHospitalId = hospitalId =>
  localStorage.setItem('DEFAULT_HOSPITAL_ID', hospitalId);

export const getDefaultHospitalId = () =>
  localStorage.getItem('DEFAULT_HOSPITAL_ID');

export const SALUTATION_REGEX = /(^(dr|mr|miss|ms|mrs)[.]*\s+){0,1}(.*)/i;

export const getNameWithoutSalutation = (name: string) => {
  if (!name) {
    return name;
  }
  const match = `${name}`.trim().match(SALUTATION_REGEX);
  return !match ? `${name}`.trim() : match[3];
};

export const getFirstNameWithoutSalutation = (name: string) => {
  return getNameWithoutSalutation(name).split(/\s+/)[0];
};

export const getFirstNameUpperCase = (name: string) =>
  getFirstNameWithoutSalutation(name).toUpperCase();

export function betterParseInt(value: string) {
  if (/^[-+]?(\d+|Infinity)$/.test(value)) {
    return Number(value);
  } else {
    return NaN;
  }
}

export const amplitudeInit = () =>
  amplitude.getInstance().init('1e8a67f9fe98ca0f56e167581a9ec6a0');

export const setAmplitudeUser = userId =>
  amplitude.getInstance().setUserId(userId);

export const logAmplitudeEvent = (
  eventType,
  // eslint-disable-next-line no-unused-vars
  eventProp = {},
  // eslint-disable-next-line no-unused-vars
  appendLoginUser
) => {};

export const getSiteCode = id => {
  if (id === 1) return 'C9-MWM';
  if (id === 3) return 'BLR';
  if (id === 4) return 'C9- WF';
  if (id === 5) return 'C9-OAR';
  if (id === 6) return 'C9-JNR';
  if (id === 7) return 'HRBR-IP';
  if (id === 8) return 'CDG';
  if (id === 9) return 'C9-CHN';
  if (id === 11) return 'C9-MFG';
  if (id === 12) return 'VSH';
  if (id === 13) return 'C9-MUM';
  if (id === 14) return 'C9NOIDA';
  if (id === 15) return 'C9-SHN';
  if (id === 16) return 'C9 - KNR';
  if (id === 20) return 'SEC 14';
  if (id === 21) return 'PCK';
  if (id === 55) return 'KPR';
  if (id === 56) return 'CCPUNE';
  else return '';
};

export const getSiteCodeFromHospitalName = hospitalName => {
  if (hospitalName === 'Cloudnine Hospital Bellandur') return 'BLR';
  if (hospitalName === 'Cloudnine Hospital Chandigarh') return 'CDG';
  if (hospitalName === 'Cloudnine Hospital Chennai') return 'C9-CHN';
  if (hospitalName === 'Cloudnine Hospital Gurgaon') return 'C9-MFG';
  if (hospitalName === 'Cloudnine Hospital Gurgaon - Sector 14')
    return 'SEC 14';
  if (hospitalName === 'Cloudnine Hospital HRBR') return 'HRBR-IP';
  if (hospitalName === 'Cloudnine Hospital Jayanagar') return 'C9-JNR';
  if (hospitalName === 'Cloudnine Hospital Kalyani Nagar') return 'C9 - KNR';
  if (hospitalName === 'Cloudnine Hospital Kanakapura Road') return 'KPR';
  if (hospitalName === 'Cloudnine Hospital Malad') return 'C9-MUM';
  if (hospitalName === 'Cloudnine Hospital Malleshwaram') return 'C9-MWM';
  if (hospitalName === 'Cloudnine Hospital Noida') return 'C9NOIDA';
  if (hospitalName === 'Cloudnine Hospital Old Airport Road') return 'C9-OAR';
  if (hospitalName === 'Cloudnine Hospital Panchkula') return 'PCK';
  if (hospitalName === 'Cloudnine Hospital Pune - SB Road') return 'C9-SHN';
  if (hospitalName === 'Cloudnine Hospital Vashi') return 'VSH';
  if (hospitalName === 'Cloudnine Hospital Whitefield') return 'C9- WF';
  if (hospitalName === 'DC - Mother and Child Clinic - Galleria') return 'NA';
  if (hospitalName === 'DC - Mother and Child Clinic - Sector 14') return 'NA';
  if (hospitalName === 'Rahul Sharma Clinic') return 'RSC';
  if (hospitalName === 'TRINITY HEALTHCARE CLINIC') return 'SEC';
  if (hospitalName === 'Mini Salunkhe Clinic') return 'CCPUNE';
  else return '';
};

export const getHospitalNameFromSiteCode = sitecode => {
  if (sitecode === 'VSH') return 'Vashi';
  if (sitecode === 'SF-OAR') return 'Old Airport Road';
  if (sitecode === 'SF-MWM') return 'Malleshwaram';
  if (sitecode === 'SF-JNR') return 'Jayanagar';
  if (sitecode === 'SF-ANX') return 'Jayanagar';
  if (sitecode === 'SEC 14') return 'Gurgaon - Sector 14';
  if (sitecode === 'S56') return 'Gurgaon - Sector 56';
  if (sitecode === 'PCK') return 'Panchkula';
  if (sitecode === 'KPR') return 'Kanakapura Road';
  if (sitecode === 'HRBR-IP') return 'HRBR';
  if (sitecode === 'CDG') return 'Chandigarh';
  if (sitecode === 'C9- WF') return 'Whitefield';
  if (sitecode === 'C9-WF') return 'Whitefield';
  if (sitecode === 'C9-SKN') return 'Sahakar Nagar';
  if (sitecode === 'C9-SHN') return 'Pune - SB Road';
  if (sitecode === 'C9 OAR Fert') return 'Old Airport Road';
  if (sitecode === 'C9-OAR') return 'Old Airport Road';
  if (sitecode === 'C9NOIDA') return 'Noida';
  if (sitecode === 'C9-MWM') return 'Malleshwaram';
  if (sitecode === 'C9-MUM') return 'Malad';
  if (sitecode === 'C9-MFG') return 'Gurgaon';
  if (sitecode === 'C9 - KNR') return 'Kalyani Nagar';
  if (sitecode === 'C9-JNR') return 'Jayanagar';
  if (sitecode === 'C9-HRBR') return 'HRBR';
  if (sitecode === 'C9-FZT') return 'Jayanagar';
  if (sitecode === 'C9-CHN') return 'Chennai';
  if (sitecode === 'C9-ANX') return 'Jayanagar';
  if (sitecode === 'C9-AECS-OP-PHARMA') return 'AECS Layout';
  if (sitecode === 'C9-AECS') return 'AECS Layout';
  if (sitecode === 'AECS OPD PH') return 'AECS Layout';
  if (sitecode === 'BLR') return 'Bellandur';
  if (sitecode === 'ADH') return 'Malad';
  if (sitecode === 'dhc') return '';
  if (sitecode === 'C9-SN-OP-PHARMA') return '';
  if (sitecode === 'C9-HB-OP-PHARMA') return '';
  if (sitecode === 'CCPUNE') return '';
};

let hospitalCache = null;
let fetchPromise = null;

export const getHospitalSiteCode = async () => {
  if (hospitalCache) {
    return hospitalCache;
  }

  // If a fetch is already in progress, wait for it to complete
  if (fetchPromise) {
    return fetchPromise;
  }

  fetchPromise = (async () => {
    const url = GQL_ENDPOINT;
    const req_body = {
      query: `{ getHospitals(showAll : true){
        id
        name
        siteCode
      }}`
    };

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(req_body)
    };

    const response = await fetch(url, fetchOpts);
    const result = await response.json();
    hospitalCache = result;
    fetchPromise = null; // Reset the fetchPromise once done
    return result;
  })();

  return fetchPromise;
};

export const getHospitalById = id => {
  const url = GQL_ENDPOINT;
  const req_body = {
    query: `{ getHospitalById(id : ${parseInt(id)}){
      id
      siteCode
      name
      cityId
      cityName }}`
  };

  const fetchOpts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(req_body)
  };

  return fetch(url, fetchOpts).then(r => r.json());
};

export const authAPI = async ({ method, body, query = '' }) => {
  const url = `${COGNITO_BASE_URL}/admin/doctors`;

  const fetchOpts = {
    method,
    headers: {
      accept: 'application/json',
      'c9-auth-key': `${COGNITO_API_KEY}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  const response = await fetch(`${url}${query}`, fetchOpts).catch(err => {
    console.log(err);
  });

  if (!response)
    return {
      status: false,
      message: 'Something went wrong'
    };
  return response.json();
};

export const getDoctorRankings = hospitalId => {
  const url = GQL_ENDPOINT;

  const req_body = {
    query: `query getDoctorsRanking($input: RankingSearchInput){
      getDoctorsRanking(input: $input){
        ranking
        doctorId
        doctorName
        primarySpecialty
        secondarySpecialty
        department
        dateSincePracticing
      }
    }
    `,
    variables: {
      input: {
        hospId: hospitalId
      }
    }
  };

  const fetchOpts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(req_body)
  };

  return fetch(url, fetchOpts)
    .then(response => {
      return response.json();
    })
    .catch(err => {
      return err;
    });
};

export const trimisterOptions = [
  { label: 'Trimester 1', value: '1' },
  { label: 'Trimester 2', value: '2' },
  { label: 'Trimester 3', value: '3' }
];

export const scanTypeOptions = [
  { label: 'Pregnancy', value: 'Pregnancy' },
  { label: 'Pregnancy procedures', value: 'Pregnancy procedures' },
  { label: 'Gynae', value: 'Gynae' },
  { label: 'Others', value: 'Others' }
];

export const pregnancyWeekOptions = [
  { label: '0-12 weeks', value: '0-12 weeks' },
  { label: '14-17 week', value: '14-17 week' },
  { label: '18-21 week', value: '18-21 week' },
  { label: '20-24 week', value: '20-24 week' },
  { label: '28+ weeks', value: '28+ weeks' }
];

export const commonCouponFormData = {
  selectedLtItemId: '',
  selectedSiteCode: '',
  selectedStartDate: moment(new Date()).format('YYYY-MM-DD'),
  selectedExpiryDate: moment()
    .add(1, 'years')
    .format('YYYY-MM-DD'),
  enteredComment: ''
};

export const recommendationTypeOptions = [
  { label: 'Recommended', value: 'Recommended' },
  { label: 'Prescribed', value: 'Prescribed' }
];
