import classnames from 'classnames';
import { ErrorMessage, Field, Formik } from 'formik';
import omit from 'lodash/omit';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import TextArea from 'react-autosize-textarea';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { amplitudeEvents } from '../amplitudeEvents';
import { DEFAULT_DOC_FEMALE, DEFAULT_DOC_MALE, vcApps } from '../constants';
import { GET_DOCTOR } from '../pages/Doctors';
import { getLoggedInUser } from '../permissions';
import { UPDATE_DOCTOR_PROFILE_LOGS } from '../queries';
import { betterParseInt, displayError, logAmplitudeEvent } from '../utilities';
import ConfirmationDialog from './ConfirmationDialog';
import LookupSelect from './LookupSelect';
import SelectLanguages from './SelectLanguages';
import UpdateInfoSection from './UpdateInfoButton';
import UploadDP from './UploadDP';

const validateDoc = Yup.object().shape({
  aboutMe: Yup.string()
    .min(100, 'About Me field should have minimum 100 characters')
    .required('Required'),
  awards: Yup.string().nullable(),
  departmentId: Yup.number('Department id must be a number')
    .integer()
    .required('Required'),
  email: Yup.string()
    .email('Email does not seem right.')
    .required('Required'),
  gender: Yup.string().required('Required'),
  employmentType: Yup.string().required('Required'),
  medicalCouncilRegNo: Yup.string().nullable(),
  name: Yup.string().required('Required'),
  phoneNumber: Yup.string().required('Required'),
  qualification: Yup.string().required('Required'),
  lifetrenzId: Yup.number()
    .integer()
    .required('Required'),
  globalRank: Yup.number()
    .integer('Rank should be a number')
    .required('Required')
    .typeError('Rank should be a number')
    .min(0, 'Global rank must be non negative'),
  ancCard: Yup.string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .matches(/\.(jpeg|jpg|png)$/)
    .typeError('Please enter correct URL')
    .nullable(),
  languages: Yup.array()
    .of(Yup.string())
    .min(1, 'At least one language must be selected')
    .required('Required'),
  followUp: Yup.string()
    .required('Required')
    .test('isNumeric', 'The follow up must be a number', value => {
      const val = betterParseInt(value);
      return !isNaN(val);
    })
    .test('isInvalid', 'The follow up range is invalid', value => {
      const val = betterParseInt(value);
      return val >= 0 && val <= 10;
    })
});

const TxtInput = ({ label, field, form: { errors }, required, ...props }) => (
  <Row style={{ margin: '0.2rem' }}>
    <Col sm={5}>
      <label className={classnames({ error: !!errors[field.name] })}>
        {label}{' '}
        {required && <span style={{ fontSize: '1.2em', color: 'red' }}>*</span>}
      </label>
    </Col>
    <Col>
      <input
        {...field}
        {...props}
        type="text"
        className={classnames({ error: !!errors[field.name] })}
        style={{ width: '100%' }}
      />
      <ErrorMessage name={field.name} component="span" className="error" />
    </Col>
  </Row>
);
TxtInput.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object,
  form: PropTypes.object,
  required: PropTypes.bool
};

const FixedText = ({ label, value }) => (
  <Row style={{ margin: '0.2rem' }}>
    <Col sm={5}>
      <label>{label}</label>
    </Col>
    <Col>
      <span>{value || 'Not Available'}</span>
    </Col>
  </Row>
);

FixedText.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

const DisabledText = ({ label, value }) => (
  <Row style={{ margin: '0.2rem' }}>
    <Col sm={5}>
      <label>{label}</label>
    </Col>
    <Col>
      <span style={{ color: 'grey', fontStyle: 'italic' }}>
        {value || 'Not Available'}
      </span>
    </Col>
  </Row>
);

DisabledText.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

const DisabledObject = ({ label, value }) => (
  <Row style={{ margin: '0.2rem' }}>
    <Col sm={5}>
      <label>{label}</label>
    </Col>
    <Col>
      <span style={{ color: 'grey', fontStyle: 'italic' }} type="text">
        {value || 'Not Available'}
      </span>
    </Col>
  </Row>
);

DisabledObject.propTypes = {
  label: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired
};

const Checkbox = ({ label, field, form: { errors }, ...props }) => (
  <Row style={{ margin: '0.2rem' }}>
    <Col sm={5}>
      <label className={classnames({ error: !!errors[field.name] })}>
        {label}
      </label>
    </Col>
    <Col>
      <input
        type="checkbox"
        {...field}
        {...props}
        checked={field.value}
        className={classnames({ error: !!errors[field.name] })}
      />
      <ErrorMessage name={field.name} component="span" className="error" />
    </Col>
  </Row>
);
Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object,
  form: PropTypes.object
};

const SelectInput = ({ label, field, form, options, required }) => {
  const favorableValue = options.find(o => o.value === field.value);
  return (
    <Row style={{ margin: '0.2rem' }}>
      <Col sm={5}>
        <label>
          {label}{' '}
          {required && (
            <span style={{ fontSize: '1.2em', color: 'red' }}>*</span>
          )}
        </label>
      </Col>
      <Col>
        <Select
          value={favorableValue}
          onChange={option => form.setFieldValue(field.name, option.value)}
          options={options}
        />
        <ErrorMessage name={field.name} component="span" className="error" />
      </Col>
    </Row>
  );
};

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object,
  form: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
      ]).isRequired
    })
  ).isRequired,
  required: PropTypes.bool
};

const TxtArea = ({ label, field, required, ...props }) => (
  <Row style={{ margin: '0.2rem' }}>
    <Col sm={5}>
      <label>
        {label}{' '}
        {required && <span style={{ fontSize: '1.2em', color: 'red' }}>*</span>}
      </label>
    </Col>
    <Col>
      <TextArea {...field} {...props}>
        {field.value}
      </TextArea>
      <ErrorMessage name={field.name} component="span" className="error" />
    </Col>
  </Row>
);
TxtArea.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object,
  form: PropTypes.object,
  required: PropTypes.bool
};

const DateField = ({ label, field, form }) => {
  const selected = field.value ? moment(field.value, 'YYYY-MM-DD') : undefined;
  return (
    <Row style={{ margin: '0.2rem' }}>
      <Col sm={5}>
        <label>{label}</label>
      </Col>
      <Col>
        <DatePicker
          style={{ padding: '0.1rem', width: '100%' }}
          onChange={v => form.setFieldValue(field.name, v.format('YYYY-MM-DD'))}
          selected={selected}
          dateFormat="D MMMM, YYYY"
          scrollableYearDropdown
          showYearDropdown
          maxDate={moment()}
        />
      </Col>
    </Row>
  );
};

DateField.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object,
  form: PropTypes.object
};

const LangInput = ({ label, field, form }) => {
  const value = field.value || [];
  return (
    <Row style={{ margin: '0.2rem' }}>
      <Col sm={5}>
        <label>{label}</label>
      </Col>
      <Col>
        <SelectLanguages
          value={value}
          onChange={value => form.setFieldValue(field.name, value)}
        />
      </Col>
    </Row>
  );
};

LangInput.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object,
  form: PropTypes.object
};

const LangLookupInput = ({ label, field, form, required }) => {
  const value = field.value || [];
  return (
    <Row style={{ margin: '0.2rem' }}>
      <Col sm={5}>
        <label>
          {label}{' '}
          {required && (
            <span style={{ fontSize: '1.2em', color: 'red' }}>*</span>
          )}
        </label>
      </Col>
      <Col>
        <LookupSelect
          lookup="lang"
          isMulti
          value={value}
          onChange={value => form.setFieldValue(field.name, value)}
        />
        <ErrorMessage component="span" name={field.name} className="error" />
      </Col>
    </Row>
  );
};

LangLookupInput.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object,
  form: PropTypes.object,
  required: PropTypes.bool
};

const SplLookupInput = ({ label, field, form }) => {
  const value = field.value || [];
  return (
    <Row style={{ margin: '0.2rem' }}>
      <Col sm={5}>
        <label>{label}</label>
      </Col>
      <Col>
        <LookupSelect
          lookup="spl"
          isMulti={false}
          value={value}
          onChange={value => form.setFieldValue(field.name, value)}
        />
      </Col>
    </Row>
  );
};

SplLookupInput.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object,
  form: PropTypes.object
};

const ServicesLookupInput = ({ label, field, form }) => {
  const value = field.value || [];
  return (
    <Row style={{ margin: '0.2rem' }}>
      <Col sm={5}>
        <label>{label}</label>
      </Col>
      <Col>
        <LookupSelect
          lookup="service"
          isMulti
          value={value}
          onChange={value => form.setFieldValue(field.name, value)}
        />
      </Col>
    </Row>
  );
};

ServicesLookupInput.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object,
  form: PropTypes.object
};

const TagsLookupInput = ({ label, field, form }) => {
  const value = field.value || [];
  return (
    <Row style={{ margin: '0.2rem' }}>
      <Col sm={5}>
        <label>{label}</label>
      </Col>
      <Col>
        <LookupSelect
          lookup="tags"
          isMulti
          value={value}
          onChange={value => form.setFieldValue(field.name, value)}
        />
      </Col>
    </Row>
  );
};

TagsLookupInput.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object,
  form: PropTypes.object
};

const VcAppLookupInput = ({ label, field, form, ...props }) => {
  const value = field.value || [];
  const options = vcApps.map(app => ({ value: app, label: app }));

  return (
    <Row style={{ margin: '0.2rem' }}>
      <Col sm={5}>
        <label>{label}</label>
      </Col>
      <Col>
        <Select
          options={options}
          value={options.find(option => option.value === value)}
          onChange={sel => {
            form.setFieldValue(field.name, sel && sel.value);
          }}
          {...props}
        />
      </Col>
    </Row>
  );
};

VcAppLookupInput.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object,
  form: PropTypes.object
};

const DocFormFields = props => {
  const { doctor, departments, updateDoc, loading } = props;
  const user = getLoggedInUser();
  const [remarks, setRemarks] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState(null);
  const [confirmationData, setConfirmationData] = useState({
    title: '',
    message: '',
    buttonText: ''
  });
  const deptOpts = departments.map(d => ({ label: d.name, value: d.id }));
  const selectedOpt = deptOpts.find(o => o.value === doctor.departmentId);
  const { photos, profileStatus } = doctor;
  let dpURL = photos && photos.dp300 && photos.dp300.url;

  if (!dpURL && doctor.gender === 'Male') {
    dpURL = DEFAULT_DOC_MALE;
  } else if (!dpURL) {
    dpURL = DEFAULT_DOC_FEMALE;
  }
  const initValue = omit(doctor, [
    '__typename',
    'entityId',
    'department',
    'photos',
    'cloudinaryId',
    'ltFirstSyncData',
    'ltLastSyncData',
    'ltCreatedAt',
    'ltUpdatedAt',
    'created_at',
    'updated_at',
    'entity',
    'siteCode'
  ]);
  // remove the redundant one

  const [updateDoctorProfileLogs, { loading: isLoading }] = useMutation(
    UPDATE_DOCTOR_PROFILE_LOGS,
    {
      refetchQueries: [{ query: GET_DOCTOR, variables: { id: doctor.id } }]
    }
  );

  const handleApprove = doctor => {
    setConfirmationAction(() => () => handleApproveAction(doctor));
    setConfirmationData({
      title: 'Approve Doctor Profile',
      message: `Are you sure to approve Dr. ${doctor.name}'s profile?`,
      buttonText: 'Approve'
    });
    setShowConfirmation(true);
  };

  const handleReject = doctor => {
    setConfirmationAction(() => () => handleRejectAction(doctor));
    setConfirmationData({
      title: 'Reject Doctor Profile',
      message: `Are you sure to reject Dr. ${doctor.name}'s profile?`,
      buttonText: 'Reject'
    });
    setShowConfirmation(true);
  };

  const handleSendForApproval = async doctor => {
    try {
      await validateDoc.validate(doctor, { abortEarly: false });
      setConfirmationAction(() => () => handleSendForApprovalAction(doctor));
      setConfirmationData({
        title: 'Send Doctor Profile for Approval',
        message: `Are you sure to send Dr. ${doctor.name}'s profile for approval?`,
        buttonText: 'Send'
      });
      setShowConfirmation(true);
    } catch (validationErrors) {
      const errorMessage = validationErrors.errors.join(', ');
      toast.error(`Validation failed: ${errorMessage}`, {
        autoClose: 3000
      });
    }
  };

  const handleApproveAction = async doctor => {
    const { data, errors } = await updateDoctorProfileLogs({
      variables: {
        DoctorProfileLogsInput: {
          id: doctor.id,
          status: 'approved',
          meta: doctor,
          remarks
        }
      }
    });

    if (data) {
      toast.success('Doctor profile has approved successfully', {
        autoClose: 3000
      });
    } else if (errors) {
      toast.error('Something went wrong', { autoClose: 3000 });
    }

    setShowConfirmation(false);
  };

  const handleRejectAction = async doctor => {
    const { data, errors } = await updateDoctorProfileLogs({
      variables: {
        DoctorProfileLogsInput: {
          id: doctor.id,
          status: 'rejected',
          meta: doctor,
          remarks
        }
      }
    });

    if (data) {
      toast.error('Doctor profile has rejected', { autoClose: 3000 });
    } else if (errors) {
      toast.error('Something went wrong', { autoClose: 3000 });
    }

    setShowConfirmation(false);
  };

  const handleSendForApprovalAction = async doctor => {
    const { data, errors } = await updateDoctorProfileLogs({
      variables: {
        DoctorProfileLogsInput: {
          id: doctor.id,
          status: 'pending',
          meta: doctor
        }
      }
    });

    if (data) {
      toast.success('Doctor profile has sent for approval successfully', {
        autoClose: 3000
      });
    } else if (errors) {
      toast.error('Something went wrong', { autoClose: 3000 });
    }

    setShowConfirmation(false);
  };

  const handleConfirm = () => {
    if (confirmationAction) {
      confirmationAction();
    }
    setShowConfirmation(false);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  return (
    <div>
      <Formik
        key={`doc-${doctor.id}`}
        initialValues={initValue}
        enableReinitialize
        validationSchema={validateDoc}
        onSubmit={values => {
          // parsing string to number
          values.followUp = parseInt(values.followUp);

          const { dateOfJoining, employmentType } = values;
          if (!dateOfJoining || dateOfJoining.trim().length < 1) {
            delete values.dateOfJoining;
          } else {
            const date = new Date(dateOfJoining);
            values.dateOfJoining = date.toISOString().split('T')[0];
          }

          if (!employmentType) {
            delete values.employmentType;
          }

          delete values.isConfigured;
          delete values.degree;
          delete values.fellowships;
          delete values.experience;
          delete values.papersPublished;
          delete values.approxNoOfDeliveries;
          delete values.highlights;
          delete values.dateSincePracticing;
          delete values.yearsOfExperience;
          delete values.displaySpecialtyArray;
          delete values.displaySpecialty;

          if (typeof values.hasOp !== 'boolean') {
            delete values.hasOp;
          }
          if (typeof values.hasOt !== 'boolean') {
            delete values.hasOt;
          }
          if (typeof values.hasIp !== 'boolean') {
            delete values.hasIp;
          }
          if (typeof values.addToWebsite !== 'boolean') {
            delete values.addToWebsite;
          }
          if (typeof values.isEnabled !== 'boolean') {
            delete values.isEnabled;
          }
          if (typeof values.isVisible !== 'boolean') {
            delete values.isVisible;
          }
          if (!values.ancCard) {
            values.ancCard = '';
          }
          if (!values.ancCardSettings) {
            values.ancCardSettings = null;
          }
          if (!values.websiteURL) {
            values.websiteURL = '';
          }

          delete values.hospitals;
          if (values.aboutMe.trim().length < 100) {
            displayError(`About Me field should have minimum 100 characters`);
            return;
          } else {
            values.aboutMe = values.aboutMe.trim();
          }
          const gr = betterParseInt(values.globalRank);
          if (isNaN(gr)) {
            displayError(`Global rank must be a number`);
            return;
          } else {
            values.globalRank = gr;
          }

          if (doctor['name'] !== values['name']) {
            logAmplitudeEvent(
              amplitudeEvents.Doctors_Name_Updated,
              { oldName: doctor['name'], newName: values['name'] },
              true
            );
          }
          if (doctor['phoneNumber'] !== values['phoneNumber']) {
            logAmplitudeEvent(
              amplitudeEvents.Doctors_Contact_Numbers_Updated,
              {
                oldPhoneNumber: doctor['phoneNumber'],
                newPhoneNumber: values['phoneNumber']
              },
              true
            );
          }
          if (doctor['email'] !== values['email']) {
            logAmplitudeEvent(
              amplitudeEvents.Doctors_Email_Updated,
              { oldEmail: doctor['email'], newEmail: values['email'] },
              true
            );
          }
          if (doctor['aboutMe'] !== values['aboutMe']) {
            logAmplitudeEvent(
              amplitudeEvents.Doctors_About_Me_Updated,
              { oldAboutMe: doctor['aboutMe'], newAboutMe: values['aboutMe'] },
              true
            );
          }
          updateDoc({
            variables: { docInput: { ...values, siteCode: doctor.siteCode } }
          });
        }}
      >
        {({ handleSubmit }) => {
          // console.error('Errors', errors);
          return (
            <Row>
              <Col>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '1rem',
                    backgroundColor: '#564088',
                    padding: '2rem',
                    borderRadius: '1rem'
                  }}
                >
                  <UploadDP url={dpURL} doctorId={doctor.id} />
                </div>
                <FixedText label="Site Code" value={doctor.siteCode} />
                <Field name="name" label="Name" component={TxtInput} />
                <Field
                  name="phoneNumber"
                  label="Contact Numbers"
                  component={TxtInput}
                  required
                />
                <Field
                  name="employmentType"
                  label="Employment Type"
                  options={[
                    { label: 'In House', value: 'InHouse' },
                    { label: 'Visiting Consultant', value: 'VC' }
                  ]}
                  component={SelectInput}
                  required
                />
                <Field
                  name="email"
                  label="Email"
                  component={TxtInput}
                  required
                />
                <Field
                  name="gender"
                  label="Gender"
                  options={[
                    { label: 'Male', value: 'Male' },
                    { label: 'Female', value: 'Female' }
                  ]}
                  component={SelectInput}
                  required
                />
                <Field
                  name="qualification"
                  label="Qualification"
                  component={TxtInput}
                  required
                />
                <Field
                  name="aboutMe"
                  label="About Me"
                  component={TxtArea}
                  required
                />
                <Field
                  name="primarySpecialty"
                  label="Primary Speciality"
                  component={SplLookupInput}
                />
                <Field
                  name="secondarySpecialty"
                  label="Secondary Speciality"
                  component={SplLookupInput}
                />
                <Field
                  component={SelectInput}
                  name="departmentId"
                  options={deptOpts}
                  value={selectedOpt}
                  label="Department"
                  required
                />
                <Field
                  name="medicalCouncilRegNo"
                  label="Registration Number"
                  component={TxtInput}
                />
                <Field
                  name="dateOfJoining"
                  label="Joining Date"
                  component={DateField}
                />
                <Field
                  name="websiteURL"
                  label="Website URL"
                  component={TxtInput}
                />
              </Col>
              <Col>
                <Field
                  name="services"
                  label="Services"
                  component={ServicesLookupInput}
                />
                <Field name="tags" label="Tags" component={TagsLookupInput} />
                <Field name="awards" label="Awards" component={TxtInput} />
                <Field
                  name="membership"
                  label="Membership"
                  component={TxtInput}
                />
                <Field
                  name="languages"
                  label="Languages"
                  component={LangLookupInput}
                  required
                />
                <Field
                  name="globalRank"
                  label="Global Rank"
                  component={TxtInput}
                  required
                />
                <Field
                  name="altDepartment"
                  label="Alternate Department"
                  component={TxtInput}
                />
                <Field
                  name="cloudinaryId"
                  label="Default Charges"
                  component={TxtInput}
                />
                <Field
                  name="ancCard"
                  label="ANC Card Template Image"
                  component={TxtInput}
                />
                <DisabledObject
                  label="ANC Card Settings"
                  value={JSON.stringify(doctor.ancCardSettings, null, 2)}
                />
                <Field
                  name="lifetrenzId"
                  label="Lifetrenz Id"
                  component={TxtInput}
                  required
                />
                <DisabledText label="AMS Doctor Id" value={doctor.id} />
                <Field
                  name="seoDescription"
                  label="Description for SEO"
                  component={TxtArea}
                />
                <Field
                  name="notesAbbrevations"
                  label="Notes and Abbrevations"
                  component={TxtArea}
                />
                <Field
                  name="seoTitle"
                  label="Title for SEO"
                  component={TxtArea}
                />
                <Field
                  name="hasOp"
                  label="Has OP Access?"
                  component={Checkbox}
                />
                <Field name="seoSlug" label="SEO Slug" component={TxtInput} />
                <Field
                  name="customSmsSuffix"
                  label="SMS Suffix"
                  component={TxtInput}
                />
                <Field
                  name="coordinatorPhoneNumber"
                  label="Co-Ordinator Number"
                  component={TxtInput}
                />
                <Field
                  name="followUp"
                  label="Can follow up to"
                  component={TxtInput}
                  required
                />
                <Field
                  name="hasOt"
                  label="Has OT Access?"
                  component={Checkbox}
                />
                <Field
                  name="hasIp"
                  label="Has IP Access?"
                  component={Checkbox}
                />

                <Field
                  name="vcApp"
                  label="Video Consultation App"
                  component={VcAppLookupInput}
                />
                <Field
                  name="addToWebsite"
                  label="Add to website?"
                  component={Checkbox}
                />

                {profileStatus === 'approved' && (
                  <>
                    <Field
                      label="Visible?"
                      name="isVisible"
                      component={Checkbox}
                      // initialChecked={true}
                    />
                    <Field
                      name="isEnabled"
                      label="Enabled?"
                      component={Checkbox}
                    />
                  </>
                )}
                {profileStatus === 'pending' && user.canApproveDoctors && (
                  <>
                    <div>
                      <strong>
                        <Field
                          name="remarks"
                          label="Remarks"
                          component={TxtArea}
                          onBlur={e => setRemarks(e.target.value)}
                        />
                      </strong>
                    </div>

                    <div className="text-right">
                      <Button
                        size={'sm'}
                        color="success"
                        style={{
                          marginTop: '10px',
                          marginRight: '10px',
                          marginBottom: '50px'
                        }}
                        onClick={() => handleApprove(doctor)}
                        disabled={isLoading}
                      >
                        Approve
                      </Button>{' '}
                      <Button
                        size={'sm'}
                        color="danger"
                        style={{
                          marginTop: '10px',
                          marginRight: '10px',
                          marginBottom: '50px'
                        }}
                        onClick={() => handleReject(doctor)}
                        disabled={isLoading}
                      >
                        Reject
                      </Button>
                    </div>
                  </>
                )}
                <UpdateInfoSection
                  user={user}
                  loading={loading}
                  handleSubmit={handleSubmit}
                  handleSendForApproval={handleSendForApproval}
                  doctor={doctor}
                  isLoading={isLoading}
                />
                <ConfirmationDialog
                  show={showConfirmation}
                  onConfirm={handleConfirm}
                  onCancel={handleCancel}
                  title={confirmationData.title}
                  message={confirmationData.message}
                  buttonText={confirmationData.buttonText}
                />
              </Col>
            </Row>
          );
        }}
      </Formik>
    </div>
  );
};

DocFormFields.propTypes = {
  doctor: PropTypes.object.isRequired,
  departments: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateDoc: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export {
  Checkbox,
  DateField,
  FixedText,
  LangInput,
  LangLookupInput,
  SelectInput,
  ServicesLookupInput,
  SplLookupInput,
  TagsLookupInput,
  TxtArea,
  TxtInput,
  validateDoc
};

export default DocFormFields;
