import PropTypes from 'prop-types';
import axios from 'axios';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { USER_SERVICE_URL, S2S_USER_SERVICES_API_KEY } from '../constants';
import { getFullName } from '../utilities';
export default class PatientSearchByPhone extends Component {
  state = {};
  fetchPatient = async mobileRaw => {
    const mobile = `${mobileRaw}`.trim().slice(0, 10);
    if (mobile.length < 10) return;
    const res = await axios.post(
      USER_SERVICE_URL,
      {
        query: `
          query GetPatientDetails($input: MpidSearchInput!) {
            getPatientDetails(input: $input) {
              id
              mobile
              mpid
              first_name
              last_name
              email
              date_of_birth
            }
          }
        `,
        variables: {
          input: {
            mobile: mobile
          }
        }
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': `${S2S_USER_SERVICES_API_KEY}`
        }
      }
    );

    const list = res.data.data.getPatientDetails
      .filter(r => !r.isDeleted)
      .map(r => ({
        label: getFullName(r.first_name, r.last_name) + ' - ' + r.mpid,
        value: r
      }));

    list.push({
      label: 'Create New Patient',
      value: { isNew: true, mobile }
    });
    return list;
  };

  handleInputChange = (inputRaw, { action }) => {
    // console.log('slot inp ' + input + ', act ' + JSON.stringify(action));
    const input = `${inputRaw}`.trim().replace(/\D/, '');
    if (input && input.length > 10) {
      this.setState({ value: input.slice(0, 10) });
    } else {
      this.setState({ value: input });
    }
    // TODO: figure out why it does not render input if next line
    // placed at the top of the function
    if (action !== 'input-change') return;
    this.props.onInputChange(input);
  };

  render() {
    const { onChange } = this.props;
    return (
      <AsyncSelect
        cacheOptions
        inputValue={this.state.value}
        loadOptions={mobile => this.fetchPatient(mobile)}
        onChange={opt => onChange(opt.value)}
        onInputChange={this.handleInputChange}
        maxMenuHeight={200}
      />
    );
  }
}

PatientSearchByPhone.propTypes = {
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  hospitalId: PropTypes.number
};
