import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { FaEdit } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import { Button, Collapse, Table } from 'reactstrap';
import AdminUserModal from '../components/AdminUserModal';
import AuthRequired from '../components/AuthRequired';
import Loader from '../components/Loader';
import ResetPassword from '../components/ResetPassword';
import SelectRoles from '../components/SelectRoles';
import { SEARCH_ADMIN_USERS } from '../queries';
import DeleteUser from './DeleteUser';

const styles = {
  page: {
    margin: '1rem'
  },
  toolbar: {
    marginBottom: '1rem'
  }
};

const AdminUsers = () => {
  const [currentEditUser, setUser] = useState();
  const [showSearch, setShowSearch] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState({});

  const hideModal = () => setUser(undefined);
  const editUser = user => {
    setUser(user);
  };

  const setPage = ({ selected }) =>
    setSearchCriteria({ ...searchCriteria, page: selected });

  return (
    <AuthRequired pageName="users">
      <div style={styles.page}>
        <h1>Admin Users</h1>
        <div style={styles.toolbar}>
          <Button color="primary" size="sm" onClick={() => editUser({})}>
            Add New
          </Button>{' '}
          <Button
            color="primary"
            size="sm"
            onClick={() => setShowSearch(!showSearch)}
          >
            {!showSearch ? 'Show' : 'Hide'} Search
          </Button>
          <Collapse isOpen={showSearch}>
            <Formik
              initialValues={searchCriteria}
              onSubmit={values => {
                const { name, roles, email } = values;
                const searchC = {};
                if (name && name.trim().length > 3) {
                  searchC.name = name.trim();
                }
                if (roles && roles.length > 0) {
                  searchC.roles = roles;
                }
                if (email && email.trim().length > 0) {
                  searchC.email = email.trim();
                }

                const { orderBy, isAsc } = searchCriteria;
                const s = { orderBy, isAsc, ...searchC };
                setSearchCriteria(s);
              }}
              render={() => (
                <Form>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '2fr 2fr 2fr 1fr',
                      gridColumnGap: '1rem',
                      margin: '1rem'
                    }}
                  >
                    <label>Name</label>
                    <label>Roles</label>
                    <label>Email</label>
                    <label>&nbsp;</label>
                    <Field name="name" />
                    <Field
                      name="roles"
                      render={({ field, form }) => (
                        <SelectRoles
                          isMulti
                          onChange={v => form.setFieldValue(field.name, v)}
                        />
                      )}
                    />
                    {/* <Field name="roles" /> */}
                    <Field name="email" />
                    <Button color="primary" type="submit">
                      search
                    </Button>
                  </div>
                </Form>
              )}
            />
          </Collapse>
        </div>
        <Query query={SEARCH_ADMIN_USERS} variables={{ input: searchCriteria }}>
          {({ loading, error, data }) => {
            if (loading) return <Loader />;
            if (error) return <div>Error: {error}</div>;
            if (!data || !data.adminUsers) {
              return <div>No admin user found</div>;
            }

            const {
              adminUsers: { users, total, currentPage, pageSize },
              hospitals
            } = data;
            const pageCount = Math.ceil(total / pageSize);
            return (
              <div>
                <div className="text-right">{`${total} records`}</div>
                <Table size="sm" striped responsive>
                  <thead>
                    <tr>
                      <th md={2}>Name</th>
                      <th md={2}>Login</th>
                      <th md={2}>Role</th>
                      <th md={2}>Groups</th>
                      <th md={4}>Hospitals</th>
                      <th md={2}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map(d => {
                      const { hospitals: uhosps } = d;
                      let hnames = '';
                      if (
                        !uhosps ||
                        uhosps.length < 1 ||
                        uhosps.length === hospitals.length
                      ) {
                        hnames = 'All';
                      } else {
                        hnames = hospitals
                          .filter(h => uhosps.includes(h.id))
                          .map(h => (
                            <div key={`${d.id}-${h.id}`}>{h.name}</div>
                          ));
                      }

                      let groups = '';
                      if (d.groups && d.groups.length) {
                        groups = d.groups.reduce((prev, curr) => {
                          return (prev ? ', ' : '') + curr;
                        }, '');
                      }

                      return (
                        <tr key={d.id}>
                          <td>{d.name}</td>
                          <td>{d.email}</td>
                          <td>{d.role}</td>
                          <td>{groups}</td>
                          <td>{hnames}</td>
                          <td>
                            <Button color="link" onClick={() => editUser(d)}>
                              <FaEdit />
                            </Button>
                            <DeleteUser
                              userId={d.id}
                              name={d.name}
                              email={d.email}
                            />
                            <span>&nbsp;|&nbsp;</span>
                            <ResetPassword adminUser={d} />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ReactPaginate
                    containerClassName="pagination"
                    pageCount={pageCount}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    initialPage={currentPage}
                    onPageChange={setPage}
                  />
                </div>
              </div>
            );
          }}
        </Query>
        <AdminUserModal
          isOpen={!!currentEditUser}
          hideModal={hideModal}
          adminUser={currentEditUser}
          onSave={hideModal}
        />
      </div>
    </AuthRequired>
  );
};
export default AdminUsers;
