import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useMutation } from 'react-apollo';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Col, Input, Row } from 'reactstrap';
import * as Yup from 'yup';
import { CREATE_HOLIDAY, EDIT_HOLIDAY } from '../queries';

const AddOrEditHoliday = props => {
  const { data, mode, refetchQueries, hospitals, handleClose } = props;

  const options = hospitals
    ? hospitals.map(hospital => ({
        value: hospital.id,
        label: hospital.name
      }))
    : [];
  const hospitalsMap = hospitals
    ? new Map(hospitals.map(hospital => [hospital.id, hospital.name]))
    : new Map();

  const [create] = useMutation(CREATE_HOLIDAY, {
    refetchQueries,
    onCompleted: handleComplete,
    onError: handleError
  });
  const [update] = useMutation(EDIT_HOLIDAY, {
    refetchQueries,
    onCompleted: handleComplete,
    onError: handleError
  });

  function handleComplete() {
    formik.setSubmitting(false);
    const message =
      mode === 'edit'
        ? 'Updated holiday successfully.'
        : 'Added holiday successfully.';
    toast.success(message, { autoClose: 3000 });
    handleClose();
  }

  function handleError(error) {
    formik.setSubmitting(false);
    toast.error(error.message, { autoClose: 3000 });
  }

  const validationSchema = Yup.object().shape({
    date: Yup.string().required('Please enter the date')
  });

  const formik = useFormik({
    initialValues: {
      date: data ? data.date : '',
      description: data ? data.description : '',
      hospitals:
        data && data.hospitalIds
          ? data.hospitalIds.map(hospId => ({
              value: hospId,
              label: hospitalsMap.get(hospId)
            }))
          : []
    },
    onSubmit: values => {
      const input = {
        date: values.date,
        description: values.description,
        hospitalIds: values.hospitals.map(hospital => hospital.value)
      };

      if (mode === 'edit') update({ variables: { input } });
      else create({ variables: { input } });
    },
    validationSchema
  });

  const handleResetForm = () => {
    formik.resetForm();
  };

  return (
    <div>
      <h4>{mode === 'edit' ? 'Edit ' : 'Add '} Holiday</h4>
      <form onSubmit={formik.handleSubmit}>
        <Row className="mt-3">
          <Col sm={12} md={3} lg={4}>
            Date{<span style={{ fontSize: '1em', color: 'red' }}>*</span>}:
          </Col>
          <Col sm={12} md={9} lg={8}>
            <input
              name="date"
              type="date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.date}
            />
            {formik.errors.date && formik.touched.date ? (
              <div className="mt-2" style={{ color: 'red', fontSize: '14px' }}>
                {formik.errors.date}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={12} md={3} lg={4}>
            <label>Description:</label>
          </Col>
          <Col sm={12} md={9} lg={8}>
            <Input
              style={{ maxWidth: '30rem' }}
              name="description"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.description}
              onBlur={formik.handleBlur}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={12} md={3} lg={4}>
            <label>Hospitals:</label>
          </Col>
          <Col>
            <Select
              isMulti
              placeholder="All Hospitals"
              name="hospitals"
              options={options}
              onBlur={formik.handleBlur}
              onChange={opts => {
                formik.setFieldValue('hospitals', opts);
              }}
              defaultValue={formik.values.hospitals}
            />
          </Col>
        </Row>
        <Row
          className="justify-content-end mt-4"
          style={{ padding: '0px 15px' }}
        >
          <Button type="button" color="primary" onClick={handleResetForm}>
            RESET
          </Button>
          <Button
            className="ml-3"
            type="submit"
            color="primary"
            disabled={formik.isSubmitting}
          >
            SAVE
          </Button>
        </Row>
      </form>
    </div>
  );
};

AddOrEditHoliday.propTypes = {
  data: PropTypes.object,
  mode: PropTypes.string,
  refetchQueries: PropTypes.array,
  hospitals: PropTypes.array,
  handleClose: PropTypes.func
};

export default AddOrEditHoliday;
