import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaRupeeSign, FaMinus, FaPlus } from 'react-icons/fa';
import { Button } from 'reactstrap';
import moment from 'moment';

const ChargesSection = styled.div`
  margin-top: 0.5rem;
  font-size: 0.7rem;
  font-weight: bold;
`;

const DoctorChargesContainer = styled.div`
  margin: -5px;
`;

const ChargesTable = styled.table`
  width: 100%;

  th,
  td {
    text-align: left;
    border-bottom: 1px solid #d3d3d3;
    vertical-align: middle;
  }

  tbody tr:last-child td {
    border-bottom: none;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  background-color: transparent;
  text-align: left;
  color: inherit;
  font-weight: bold;

  display: flex;
  align-items: center;
  gap: 0.7rem;

  border: 1px solid;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  &:hover {
    background-color: transparent;
    color: inherit;
  }

  &:focus {
    box-shadow: none;
  }
`;

const RupeeContainer = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.2rem;
`;

const Note = styled.div`
  color: red;
  font-style: italic;
  margin-left: 0.5rem;
`;

const DoctorCharges = ({ charges, isChargesOpen, toggleCharges }) => {
  const latestSyncTime = moment(charges[0].createdAt).format('DD-MMM hh:mm A');
  return (
    <DoctorChargesContainer>
      <StyledButton onClick={toggleCharges}>
        {isChargesOpen ? (
          <>
            <FaMinus size={14} /> Consultation Charges(Click to Minimize)
          </>
        ) : (
          <>
            <FaPlus size={14} />
            Consultation Charges(Click to Expand)
          </>
        )}
      </StyledButton>
      {isChargesOpen && (
        <ChargesSection>
          <Note>Note: Last synced on {latestSyncTime}</Note>
          <ChargesTable>
            <thead>
              <tr>
                <th>Procedure</th>
                <th>OP Charges</th>
                <th>Medical Records & Digitization Charges</th>
                <th>
                  <div style={{ textAlign: 'center' }}>
                    <span>Total</span>
                    <br />
                    <span style={{ fontSize: '0.6rem' }}>
                      (Exc. Registration charges)
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {charges.map((charge, index) => (
                <tr key={index}>
                  <td>{charge.serviceType}</td>
                  <td>
                    <RupeeContainer>
                      <FaRupeeSign size={9} />
                      {parseFloat(charge.charges)
                        .toFixed(2)
                        .replace(/\.00$/, '')}
                    </RupeeContainer>
                  </td>
                  <td>
                    <RupeeContainer>
                      <FaRupeeSign size={9} />
                      {parseFloat(charge.additionalCharges)
                        .toFixed(2)
                        .replace(/\.00$/, '')}
                    </RupeeContainer>
                  </td>
                  <td>
                    <RupeeContainer>
                      <FaRupeeSign size={9} />
                      {(
                        parseFloat(charge.charges) +
                        parseFloat(charge.additionalCharges || 0)
                      )
                        .toFixed(2)
                        .replace(/\.00$/, '')}
                    </RupeeContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </ChargesTable>
        </ChargesSection>
      )}
    </DoctorChargesContainer>
  );
};

DoctorCharges.propTypes = {
  charges: PropTypes.arrayOf(
    PropTypes.shape({
      serviceType: PropTypes.string.isRequired,
      charges: PropTypes.string,
      additionalCharges: PropTypes.string,
      createdAt: PropTypes.string.isRequired
    })
  ).isRequired,
  isChargesOpen: PropTypes.bool.isRequired,
  toggleCharges: PropTypes.func.isRequired
};

export default DoctorCharges;
