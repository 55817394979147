import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Mutation, Query } from 'react-apollo';
import { FaCheckCircle, FaDatabase } from 'react-icons/fa';
import {
  Button,
  Col,
  Container,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row
} from 'reactstrap';
import { permissions } from '../permissions';
import {
  ENTITY_LOGIN_CREATION,
  HAS_ENTITY_LOGIN,
  UPDATE_DOCTOR_MUTATION
} from '../queries';
import {
  // isImageBigEnough,
  // getTransformedImagesFromCloudinaryResponse,
  displayError,
  displaySuccess,
  isValidEmail
} from '../utilities';
import CheckPermissions from './CheckPermissions';
import DocFormFields from './DocFormFields';
import Loader from './Loader';
import { GET_DOCTOR } from '../pages/Doctors';

const LtSyncDataTag = ({ jsonStr, dateStr, isInitial = true }) => {
  if (!jsonStr || !dateStr) {
    return null;
  }

  return (
    <div>
      <p>
        <strong>
          {isInitial
            ? 'Imported from Lifetrenz on'
            : 'Updated from Lifetrenz on'}
        </strong>
        {` ${moment(dateStr).format('D MMM, YYYY')}`}
      </p>
      <p>
        <strong>Data:</strong>
      </p>
      <pre>{JSON.stringify(JSON.parse(jsonStr), null, 2)}</pre>
    </div>
  );
};

LtSyncDataTag.propTypes = {
  jsonStr: PropTypes.string,
  dateStr: PropTypes.string,
  isInitial: PropTypes.bool.isRequired
};

const CreateAdminUser = ({ makeLink, doc }) => (
  <Mutation
    mutation={ENTITY_LOGIN_CREATION}
    onCompleted={data => {
      if (data && data.checkAndSignupEntity) {
        const { isCreated, message } = data.checkAndSignupEntity;
        if (!isCreated) {
          displayError(message);
        } else {
          displaySuccess(message);
        }
      }
    }}
    onError={error => displayError(error)}
    refetchQueries={() => ['HAS_ENTITY_LOGIN']}
  >
    {(linkDoctor, { loading }) => {
      let loader = null;
      if (loading) {
        loader = <Loader size={5} inline />;
      }
      return (
        <span>
          {loader}
          <Button
            disabled={loading}
            color="link"
            onClick={() => makeLink(doc, linkDoctor)}
          >
            Check and create login
          </Button>
        </span>
      );
    }}
  </Mutation>
);

CreateAdminUser.propTypes = {
  makeLink: PropTypes.func.isRequired,
  doc: PropTypes.object.isRequired
};

export default class DocForm extends Component {
  state = {
    photos: undefined,
    cloudinaryId: undefined,
    isMetadataPopoverOpen: false
  };

  componentDidMount = () => this.setState({ updateFields: {} });
  toggleMetadata = () =>
    this.setState({ isMetadataPopoverOpen: !this.state.isMetadataPopoverOpen });

  render() {
    const { doc, departments, ...props } = this.props;
    const {
      entityId,
      ltFirstSyncData,
      ltLastSyncData,
      ltCreatedAt,
      ltUpdatedAt,
      created_at,
      updated_at
    } = doc;
    //make null fields empty
    const docInput = {};
    Object.entries(doc).forEach(([k, v]) =>
      v ? (docInput[k] = v) : (docInput[k] = '')
    );

    const makeLink = (doc, linkDoctor) => {
      const { name, email, entityId } = doc;
      if (!name || name.trim().length < 3) {
        displayError(
          'Name cannot be less than 3 characters. Update and save then retry.'
        );
        return;
      }

      if (!email || !isValidEmail(email)) {
        displayError(
          'Invalid email, please save doctor with valid email then retry'
        );
        return;
      }

      if (!entityId) {
        displayError(
          `Doctor does not have an entityId. Please contact support.`
        );
        return;
      }
      linkDoctor({ variables: { name, entityId, email, role: 'doctor' } });
    };

    return (
      <Container {...props}>
        <Row>
          <Col className="display-flex-right-align">
            <div>
              {/* <createAdminUser makeLink={makeLink} doc={doc} /> */}

              <CheckPermissions permissions={permissions.CAN_CREATE_DOC_LOGIN}>
                <Query query={HAS_ENTITY_LOGIN} variables={{ entityId }}>
                  {({ loading, error, data }) => {
                    if (loading) return <Loader size={5} />;
                    if (error)
                      return (
                        <span> Something went wrong checking entity login</span>
                      );

                    if (data && data.hasEntityLogin) {
                      return (
                        <span style={{ color: '#999999' }}>
                          <span
                            style={{ color: '#90EE90', fontSize: '1.5rem' }}
                          >
                            <FaCheckCircle />
                          </span>
                          &nbsp; Doctor has login
                        </span>
                      );
                    } else {
                      return <CreateAdminUser doc={doc} makeLink={makeLink} />;
                    }
                  }}
                </Query>
              </CheckPermissions>
            </div>
            <div>
              <Button color="link" id="ltmetadata">
                <FaDatabase /> View Metadata
              </Button>

              <Popover
                placement="left"
                isOpen={this.state.isMetadataPopoverOpen}
                target="ltmetadata"
                toggle={this.toggleMetadata}
              >
                <PopoverHeader>Metadata</PopoverHeader>
                <PopoverBody>
                  <div>
                    <LtSyncDataTag
                      jsonStr={ltFirstSyncData}
                      dateStr={ltCreatedAt}
                      isInitial
                    />
                    <LtSyncDataTag
                      jsonStr={ltLastSyncData}
                      dateStr={ltUpdatedAt}
                      isInitial={false}
                    />
                    <div>
                      <strong>Created at: </strong>
                      {` ${moment(created_at).format('D MMM YYYY h:mm A')}`}
                    </div>
                    <div>
                      <strong>Updated at: </strong>
                      {` ${moment(updated_at).format('D MMM YYYY h:mm A')}`}
                    </div>
                  </div>
                </PopoverBody>
              </Popover>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <CheckPermissions permissions={permissions.CAN_EDIT_DOC_ALL}>
              <Mutation
                mutation={UPDATE_DOCTOR_MUTATION}
                refetchQueries={() => [
                  { query: GET_DOCTOR, variables: { id: doc.id } }
                ]}
                onError={e => displayError(e)}
                onCompleted={({ doc }) => {
                  // console.log(`complete`, doc);
                  displaySuccess(`${doc.name} updated successfully`);
                }}
              >
                {(updateDoc, { loading }) => (
                  <React.Fragment>
                    <Row>
                      <Col>
                        <DocFormFields
                          doctor={docInput}
                          departments={departments}
                          updateDoc={updateDoc}
                          loading={loading}
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </Mutation>
            </CheckPermissions>
          </Col>
        </Row>
      </Container>
    );
  }
}

DocForm.propTypes = {
  doc: PropTypes.object,
  departments: PropTypes.arrayOf(PropTypes.object).isRequired
};
