import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { USER_SERVICE_URL, S2S_USER_SERVICES_API_KEY } from '../constants';
import axios from 'axios';

export default class SearchPatientByMobile extends Component {
  state = {};

  fetchPatientList = async mobileno => {
    const mobile = `${mobileno}`.trim().slice(0, 10);

    if (mobile.length < 10) return;

    const res = await axios.post(
      USER_SERVICE_URL,
      {
        query: `
          query GetPatientDetails($input: MpidSearchInput!) {
            getPatientDetails(input: $input) {
              id
              mobile
              mpid
              first_name
              last_name
              email
              date_of_birth
            }
          }
        `,
        variables: {
          input: {
            mobile: mobile
          }
        }
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': `${S2S_USER_SERVICES_API_KEY}`
        }
      }
    );

    const patientList = res.data.data.getPatientDetails
      .filter(obj => !obj.isDeleted)
      .map(obj => ({
        label: `${obj.first_name} - ${obj.mpid}`,
        value: obj
      }));
    console.log('patientList from SearchPatientByMobile is ', patientList);
    return patientList;
  };

  handleMobileNoChange = (inputData, { action }) => {
    const input = `${inputData}`.trim().replace(/\D/, '');

    if (input && input.length && input.length > 10) {
      this.setState({ value: input.slice(0, 10) });
    } else {
      this.setState({ value: input });
    }

    if (action !== 'input-change') return;
    this.props.onMobileNoChange(input);
  };

  render() {
    const { onChange } = this.props;
    return (
      <AsyncSelect
        cacheOptions
        inputValue={this.state.value}
        loadOptions={this.fetchPatientList}
        onChange={option => onChange(option.value)}
        onMobileNoChange={this.handleMobileNoChange}
        maxMenuHeight={200}
      />
    );
  }
}

SearchPatientByMobile.propTypes = {
  onChange: PropTypes.func,
  onMobileNoChange: PropTypes.func
};
