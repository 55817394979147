import { gql } from 'apollo-boost';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import Highlighter from 'react-highlight-words';
import { FaInfoCircle, FaPrescription } from 'react-icons/fa';
import { GiAlarmClock, GiTortoise } from 'react-icons/gi';
import { IoIosFlask } from 'react-icons/io';
import { MdPerson } from 'react-icons/md';
import { Button, Col, Container, Row } from 'reactstrap';
import { isPermitted, permissions } from '../permissions';
import { GET_ALL_HOSPITALS, GET_LOGGED_IN_USER } from '../queries';
import { getHospitalSiteCode } from '../utilities';
import ActionButton from './ActionButtonsPatientBadge';
import OrphanBadge from './OrphanBadge';
import SendBookingReminder from './SendBookingReminder';
import Tooltip from './Tooltip';
import ResendPrescription from './sendBookingPrescription';

export const statusColors = {
  Booked: '#17a2b8',
  'Checked In': '#343a40',
  'Start Consultation': '#007bff',
  'End Consultation': '#999999',
  'Checked Out': '#6c757d',
  'No Show': '#ffc107',
  Cancelled: '#dc3545'
};

const styles = {
  outer: {
    backgroundColor: '#f6f8fa',
    borderTop: '1px solid #eaedef',
    borderRight: '1px solid #eaedef',
    borderBottom: '1px solid #eaedef',
    padding: '10px',
    marginTop: '5px'
  },

  pic: {
    marginRight: '5px'
  },

  bottomRow: {
    borderTop: '1px solid #eaedef'
  },

  Booked: {
    borderLeft: `4px solid ${statusColors.Booked}`
  },
  'Checked In': {
    borderLeft: `4px solid ${statusColors['Checked In']}`
  },
  'Start Consultation': {
    borderLeft: `4px solid ${statusColors['Start Consultation']}`
  },
  'End Consultation': {
    borderLeft: `4px solid ${statusColors['End Consultation']}`
  },
  'Checked Out': {
    borderLeft: `4px solid ${statusColors['Checked Out']}`
  },
  'No Show': {
    borderLeft: `4px solid ${statusColors['No Show']}`
  },
  Cancelled: {
    borderLeft: `4px solid ${statusColors.Cancelled}`
  }
};

const DOC_QUERY = gql`
  query doctor($id: String) {
    doctor: getDoctor(id: $id) {
      name
      qualification
      lifetrenzId
      department
      hospitals: getHospitals {
        id
        name
      }
    }
  }
`;

const getSiteCodefromName = async name => {
  const getData = await getHospitalSiteCode();
  const hospData = getData.data.getHospitals;

  const hospArr = hospData.filter(opt => opt.name === name);

  return hospArr[0].siteCode;
};

export default class PatientBadge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
    this.userRole = null;
    this.toggle = this.toggle.bind(this);
    this.sitecode = localStorage.getItem('SELECTED_HOSPITAL_NAME')
      ? getSiteCodefromName(localStorage.getItem('SELECTED_HOSPITAL_NAME'))
      : null;
  }

  toggle(e) {
    e.preventDefault();
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    const {
      booking,
      booking: {
        objectName,
        id,
        mpid,
        userName,
        userEmail,
        userMobile,
        objectId,
        entityId,
        hospitalId,
        slotTime,
        userQuestions,
        appointmentState,
        procedureName,
        waitTime,
        slotId,
        rescheduledFrom,
        ltInvoicePdfUrl,
        ltReceiptId
      },
      searchPhone,
      displayDetail,
      stopPolling,
      startPolling,
      onBook,
      hospitalsIdNameMap,
      createInvoice,
      createScanInvoice,
      updateMpid,
      customerRxDetails,
      customerPersonalDetails,
      labsAndScansPrescribed
    } = this.props;

    const st = moment(slotTime);
    const titleTime = st.format('D MMM YYYY h:mm A');
    const displayTime = moment().isSame(st, 'day')
      ? moment(slotTime).format('h:mm A')
      : titleTime;
    const isPast = st.isBefore(moment());
    const hospitalName = hospitalsIdNameMap[hospitalId];
    const isStateBooked =
      appointmentState === 'Booked' || appointmentState === 'Booked and Paid';
    const isStateCheckedOut =
      appointmentState === 'Check Out' || appointmentState === 'Checked Out';
    const isStateCancelled = appointmentState === 'Cancelled';
    const shouldShowViewInvoiceBtn = Boolean(
      [
        'Booked and Paid',
        'Checked In',
        'Start Consultation',
        'End Consultation',
        'Checked Out'
      ].includes(appointmentState) && ltInvoicePdfUrl
    );
    const shouldShowCreateInvoiceBtn =
      !shouldShowViewInvoiceBtn && !isStateCancelled && !isStateCheckedOut;
    const shouldShowPrescriptionBtn = ltReceiptId;
    const shouldShowUpdatempidBtn = !mpid && !isStateCancelled;
    const token = localStorage.getItem('token');

    const viewInvoice = url => {
      const pdfUrl = `${url}?token=${token}`;
      window.open(pdfUrl, '_blank');
    };

    const viewPrescription = url => {
      const pdfUrl = `${url}?token=${token}`;
      window.open(pdfUrl, '_blank');
    };

    let waitTimeComponent = null;
    if (waitTime > 25) {
      let tortoiseScale = Math.min((waitTime - 25) * 0.1, 1);
      let tortoiseColor = 'red';
      if (waitTime < 30) {
        tortoiseColor = '#333333';
      } else if (waitTime < 45) {
        tortoiseColor = 'blue';
      }
      waitTimeComponent = (
        <span style={{ color: 'red', fontStyle: 'italic' }}>
          <GiTortoise
            color={tortoiseColor}
            title={`Delay: ${waitTime} min`}
            style={{
              fontSize: `${0.5 + tortoiseScale}rem`,
              color: tortoiseColor
            }}
          />
          {` ${waitTime} min`}
        </span>
      );
    }

    const getCreateInvoiceBtn = selectedHospObj => {
      if (!selectedHospObj) {
        return null;
      }

      if (
        shouldShowCreateInvoiceBtn &&
        isPermitted(permissions.VIEW_QUICK_APPOINTMENT_BOOK)
      ) {
        return (
          <Query query={DOC_QUERY} variables={{ id: objectId }}>
            {({ data: { doctor } = { doctor: {} } }) => {
              const { department } = doctor;
              if (
                selectedHospObj.isCloudnineHosp &&
                department === 'Radiology & Diagnostics' &&
                selectedHospObj.siteCode !== 'BJH'
              ) {
                return (
                  <Button
                    size="sm"
                    color="primary"
                    onClick={() => createScanInvoice(id, objectId)}
                  >
                    Create Scan Invoice
                  </Button>
                );
              } else if (department !== 'Radiology & Diagnostics') {
                return (
                  <Button
                    size="sm"
                    color="primary"
                    onClick={() => createInvoice(id, objectId)}
                  >
                    Create Invoice
                  </Button>
                );
              } else {
                return null;
              }
            }}
          </Query>
        );
      }

      return null;
    };

    return (
      <Query query={GET_LOGGED_IN_USER}>
        {({ data: { me } }) => {
          // fetching and storing role of user currently logged in.
          this.userRole = me.role;
          return (
            <Container
              style={Object.assign({}, styles.outer, styles[appointmentState])}
              className={classnames({
                'patient-badge-past': isPast,
                'patient-badge-future': !isPast,
                'patient-badge-container': true
              })}
            >
              <Row>
                <Col>
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col
                          style={{
                            fontWeight: 'bold',
                            textTransform: 'uppercase'
                          }}
                        >
                          <Highlighter
                            highlightClassName="highlight-matching-ph"
                            searchWords={[searchPhone]}
                            autoEscape={true}
                            textToHighlight={userName}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          Ph:{' '}
                          <Highlighter
                            highlightClassName="highlight-matching-ph"
                            searchWords={[searchPhone]}
                            autoEscape={true}
                            textToHighlight={userMobile}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>MPID: {mpid || 'NA'}</Col>
                      </Row>
                      {userEmail && userEmail !== '' ? (
                        <Row>
                          <Col>Email: {userEmail}</Col>
                        </Row>
                      ) : null}
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          {onBook ? (
                            isPermitted(
                              permissions.VIEW_QUICK_APPOINTMENT_BOOK
                            ) ? (
                              <Button
                                color="link"
                                onClick={() => onBook(objectId, entityId)}
                                style={{ padding: 0 }}
                              >
                                {objectName}
                              </Button>
                            ) : (
                              <span>{objectName}</span>
                            )
                          ) : (
                            <span>{objectName}</span>
                          )}
                          <span>
                            <SendBookingReminder
                              bookingId={id}
                              disabled={!isStateBooked}
                            />

                            <>
                              <Button
                                id="BookingDetails"
                                color="link"
                                onClick={() => displayDetail(id, mpid)}
                                style={{ padding: 0, marginLeft: '1rem' }}
                              >
                                <FaInfoCircle />
                              </Button>
                              <Tooltip
                                target="BookingDetails"
                                message="Booking Details"
                                placement="bottom"
                              />
                            </>
                            {mpid && shouldShowPrescriptionBtn && id ? (
                              <>
                                <Button
                                  id="LabandScan"
                                  color="link"
                                  onClick={() =>
                                    labsAndScansPrescribed(mpid, id)
                                  }
                                  style={{ padding: 0, marginLeft: '1rem' }}
                                >
                                  <IoIosFlask size="16" />
                                </Button>
                                <Tooltip
                                  target="LabandScan"
                                  message="Lab & Scan"
                                  placement="bottom"
                                />
                              </>
                            ) : null}

                            {/* Checking if logged in user have admin/doctor role and user mobile and mpid is present for a particular appointment, if yes, enable patient history details option. */}
                            {userMobile &&
                            mpid &&
                            (this.userRole === 'admin' ||
                              this.userRole === 'nurse' ||
                              this.userRole === 'doctor') ? (
                              <>
                                <Button
                                  color="link"
                                  id="Prescription"
                                  onClick={() =>
                                    customerRxDetails(userMobile, mpid)
                                  }
                                  style={{ padding: 0, marginLeft: '1rem' }}
                                >
                                  <FaPrescription />
                                </Button>
                                <Tooltip
                                  target="Prescription"
                                  message="Prescription"
                                  placement="bottom"
                                />
                              </>
                            ) : (
                              <Button
                                color="link"
                                onClick={() => {}}
                                style={{
                                  padding: 0,
                                  marginLeft: '1rem',
                                  cursor: 'default'
                                }}
                              >
                                <FaPrescription style={{ color: '#9da3a9' }} />
                              </Button>
                            )}
                            {mpid ? (
                              <>
                                <Button
                                  id="PersonalDetails"
                                  color="link"
                                  onClick={() => customerPersonalDetails(mpid)}
                                  style={{ padding: 0, marginLeft: '1rem' }}
                                >
                                  <MdPerson size="20" />
                                </Button>
                                <Tooltip
                                  target="PersonalDetails"
                                  message="Personal Details"
                                  placement="bottom"
                                />
                              </>
                            ) : (
                              <Button
                                color="link"
                                onClick={() => {}}
                                style={{
                                  padding: 0,
                                  marginLeft: '1rem',
                                  cursor: 'default'
                                }}
                              >
                                <MdPerson
                                  size="20"
                                  style={{ color: '#9da3a9' }}
                                />
                              </Button>
                            )}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>{procedureName}</Col>
                      </Row>
                      <Row>
                        <Col>{hospitalName}</Col>
                      </Row>
                      <Row>
                        <Col>
                          <strong>{userQuestions || 'No notes'}</strong>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xl={4} style={{ fontSize: '1.2rem' }}>
                  {isStateBooked && (
                    <OrphanBadge
                      slotId={slotId}
                      bookingId={id}
                      slotTime={slotTime}
                      rescheduledFrom={rescheduledFrom}
                    />
                  )}
                  <GiAlarmClock />{' '}
                  <span
                    id={`apptTime${id}`}
                    name="apptTime"
                    className="appt-time"
                  >
                    {`${displayTime} `}
                  </span>
                  <Tooltip
                    target={`apptTime${id}`}
                    message={titleTime}
                    placement="right"
                  />
                  {waitTimeComponent}
                </Col>
                <Col
                  xl={8}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  {isPermitted(permissions.VIEW_QUICK_APPOINTMENT_BOOK) && (
                    <ActionButton
                      booking={booking}
                      isNoShowBtnEye={false}
                      stopPolling={stopPolling}
                      startPolling={startPolling}
                      TimeOfAppointment={moment(slotTime).format(
                        'DD-MM-YYYY, HH:mm A'
                      )}
                      PatientName={userName}
                    />
                  )}
                  {shouldShowUpdatempidBtn &&
                    (isPermitted(permissions.VIEW_QUICK_APPOINTMENT_BOOK) && (
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => updateMpid(id)}
                      >
                        Update Mpid
                      </Button>
                    ))}
                  {/* <ResendPrescription
                    bookingId={id}
                    disabled={!isStateBooked}
                  /> */}
                  {shouldShowPrescriptionBtn && (
                    <>
                      <ResendPrescription
                        bookingId={id}
                        disabled={!isStateBooked}
                      />
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => viewPrescription(ltReceiptId)}
                      >
                        Prescription
                      </Button>
                    </>
                  )}
                  {shouldShowViewInvoiceBtn && (
                    <Button
                      size="sm"
                      color="success"
                      onClick={() => viewInvoice(ltInvoicePdfUrl)}
                    >
                      View Invoice
                    </Button>
                  )}
                  {
                    <div>
                      <Query
                        query={GET_ALL_HOSPITALS}
                        variables={{ showAll: true }}
                      >
                        {({ data }) => {
                          let selectedHospObj;
                          if (data && data.hospitals) {
                            selectedHospObj = data.hospitals.find(
                              ({ id }) => hospitalId === id
                            );
                          }
                          return getCreateInvoiceBtn(selectedHospObj);
                        }}
                      </Query>
                    </div>
                  }
                </Col>
              </Row>
            </Container>
          );
        }}
      </Query>
    );
  }
}

PatientBadge.propTypes = {
  booking: PropTypes.object,
  searchPhone: PropTypes.string,
  displayDetail: PropTypes.func,
  stopPolling: PropTypes.func,
  startPolling: PropTypes.func,
  onBook: PropTypes.func,
  hospitalsIdNameMap: PropTypes.object,
  createInvoice: PropTypes.func,
  createScanInvoice: PropTypes.func,
  updateMpid: PropTypes.func,
  customerRxDetails: PropTypes.func,
  customerPersonalDetails: PropTypes.func,
  labsAndScansPrescribed: PropTypes.func
};

PatientBadge.defaultProps = {
  stopPolling: () => {},
  startPolling: () => {},
  hospitalsIdNameMap: {}
};
