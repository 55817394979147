import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Collapse
} from 'reactstrap';
import moment from 'moment';
import SlotList from './SlotList';
import BookApptForm from './BookApptForm';
import Loader from './Loader';
import DatePicker from 'react-datepicker';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import { DEFAULT_DOC_MALE, DEFAULT_DOC_FEMALE } from '../constants';
import SelectProcedure from './SelectProcedure';
import Select from 'react-select';
import { getHospitalSiteCode } from '../utilities';

const styles = {
  docName: {},
  docSpl: {
    color: '#808080',
    textTransform: 'capitalize'
  },
  stDate: {
    fontWeight: 'bold'
  },
  topMargin: {
    marginTop: 10
  },
  slotHeader: {
    fontWeight: 'bold',
    fontSize: 12,
    textAlign: 'center',
    backgroundColor: '#b3b3b3',
    border: '1px solid #fff'
  },
  dayContainer: {
    border: '1px solid #c7c7c7',
    textAlign: 'center'
  },
  slotContainer: {
    borderTop: '1px solid #c7c7c7'
  },
  formLabel: {
    fontWeight: 'bold'
  }
};

const STEPS = { choose_date_type: 1, choose_slot: 2, fill_detail: 3 };
const DOC_QUERY = gql`
  query doctor($id: String) {
    doctor: getDoctor(id: $id) {
      name
      qualification
      photos {
        dp300 {
          url
        }
      }
      gender
      hospitals: getHospitals {
        id
        name
      }
    }
  }
`;

const initialState = {
  isTimingPopoverOpen: false,
  startDate: moment(),
  displayState: STEPS.choose_date_type,
  selectedSlot: undefined,
  selectedProcedure: undefined,
  selectedSlotType: ''
};

const renderOptions = props => {
  const {
    innerProps,
    innerRef,
    data: { slotType, hospital }
  } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{ padding: '0.3rem', cursor: 'default' }}
    >
      <div style={{ paddingBottom: '0rem' }}>
        <strong>{slotType}</strong>
      </div>
      <div className={'text-muted'}>
        <small>{hospital}</small>
      </div>
    </div>
  );
};

const getSiteCodeofId = async id => {
  const getData = await getHospitalSiteCode();
  const hospData = getData.data.getHospitals;

  const dataSite = hospData.filter(opt => opt.id === id);
  return dataSite[0].siteCode;
};

renderOptions.propTypes = {
  innerProps: PropTypes.any,
  innerRef: PropTypes.any,
  data: {
    slotType: PropTypes.string,
    hospital: PropTypes.string,
    value: PropTypes.any
  }
};

export default class BookingModal extends Component {
  state = Object.assign({}, initialState);
  componentDidUpdate() {
    if (
      this.props.hospitalName !== localStorage.getItem('SELECTED_HOSPITAL_NAME')
    )
      localStorage.setItem('SELECTED_HOSPITAL_NAME', this.props.hospitalName);

    if (this.props.hospitalId) {
      getSiteCodeofId(this.props.hospitalId).then(res => {
        localStorage.setItem('SELECTED_SITECODE', res);
      });
    }
  }
  componentDidMount() {
    if (
      this.props.hospitalName !== localStorage.getItem('SELECTED_HOSPITAL_NAME')
    )
      localStorage.setItem('SELECTED_HOSPITAL_NAME', this.props.hospitalName);

    if (this.props.hospitalId) {
      getSiteCodeofId(this.props.hospitalId).then(res => {
        localStorage.setItem('SELECTED_SITECODE', res);
      });
    }
  }
  onSlotSelect = selectedSlot =>
    this.setState({ selectedSlot, displayState: STEPS.fill_detail });

  resetTheState = () => this.setState(Object.assign({}, initialState));

  updateDate = date =>
    this.setState({
      startDate: date,
      displayState: STEPS.choose_slot
    });

  jumpDateBy = days => {
    const { startDate } = this.state;
    let newDate;
    newDate = moment(startDate).add(days, 'days');
    this.updateDate(newDate);
  };

  loadPrevSlots = () => {
    if (this.state.startDate.isSame(moment(), 'date')) {
      return;
    }
    this.jumpDateBy(-5);
  };
  loadNextSlots = () => this.jumpDateBy(5);

  render() {
    // console.log('rending', this.state.selectedSlotType);
    const { doctorId, entityId, isOpen, toggle, hospitalId } = this.props;
    const {
      startDate,
      selectedSlot,
      displayState,
      selectedProcedure,
      selectedHospital
    } = this.state;

    if (!doctorId) {
      return null;
    }

    const effectiveHospitalId = selectedHospital || hospitalId;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        onClosed={this.resetTheState}
        keyboard={false}
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>Book an appointment</ModalHeader>
        <ModalBody>
          <Container>
            <Query query={DOC_QUERY} variables={{ id: doctorId }}>
              {({ loading, data: { doctor } = { doctor: {} } }) => {
                if (loading) {
                  return <Loader loading={true} />;
                }

                const {
                  // slotTypes,
                  photos,
                  name,
                  qualification,
                  gender,
                  hospitals: docHospitals
                } = doctor;

                const docOpts = docHospitals.map(({ id, name }) => ({
                  label: name,
                  value: id
                }));

                const selectedDocOpt = docOpts.find(
                  ({ value }) => value === effectiveHospitalId
                );

                const dpURL =
                  photos && photos.dp300 ? photos.dp300.url : undefined;
                let displayUrl = dpURL;

                if (!dpURL && gender === 'Male') {
                  displayUrl = DEFAULT_DOC_MALE;
                } else if (!dpURL) {
                  displayUrl = DEFAULT_DOC_FEMALE;
                }

                return (
                  <Row>
                    <Col sm={2}>
                      <img
                        src={displayUrl}
                        alt={name}
                        height={100}
                        width={100}
                      />
                    </Col>
                    <Col sm={10}>
                      <Row>
                        <Col style={styles.docName}>
                          <h4 style={{ display: 'inline' }}>{name}</h4>{' '}
                          <span style={styles.docSpl}>{qualification}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h6>
                            <Select
                              value={selectedDocOpt}
                              options={docOpts}
                              onChange={({ value }) =>
                                this.setState({
                                  selectedHospital: value,
                                  selectedProcedure: undefined
                                })
                              }
                            />
                          </h6>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '1rem' }}>
                        <Col sm={6}>
                          <DatePicker
                            selected={startDate}
                            minDate={moment()}
                            maxDate={moment().add('90', 'day')}
                            dateFormat="ddd DD MMM, YYYY"
                            onChange={date => this.updateDate(date)}
                          />
                        </Col>
                        <Col sm={6}>
                          <SelectProcedure
                            hospitalId={effectiveHospitalId}
                            entityId={entityId}
                            selectedProcedure={selectedProcedure}
                            onProcedureSelect={selectedProcedure => {
                              this.setState({
                                selectedProcedure,
                                displayState: STEPS.choose_slot
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              }}
            </Query>
            <Row style={styles.topMargin}>
              <Col>
                <Card>
                  <CardHeader
                    onClick={() =>
                      this.setState({ displayState: STEPS.choose_slot })
                    }
                  >
                    {'Step 1 > Select Appointment Time'}
                  </CardHeader>
                  <Collapse isOpen={displayState === STEPS.choose_slot}>
                    <CardBody>
                      {displayState === STEPS.choose_slot && (
                        <SlotList
                          startDate={startDate}
                          entityId={entityId}
                          onSlotSelect={this.onSlotSelect}
                          procedureName={selectedProcedure}
                          hospitalId={effectiveHospitalId}
                          onPrevClick={this.loadPrevSlots}
                          onNextClick={this.loadNextSlots}
                        />
                      )}
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
            </Row>
            {selectedProcedure !== 'Video Consultation' ? (
              <Row style={styles.topMargin}>
                <Col>
                  <Card>
                    <CardHeader>{'Step 2 > Enter Patient Details'}</CardHeader>
                    <Collapse isOpen={displayState === STEPS.fill_detail}>
                      <CardBody>
                        <BookApptForm
                          hospitalId={effectiveHospitalId}
                          selectedSlot={selectedSlot}
                          procedureName={selectedProcedure}
                        />
                      </CardBody>
                    </Collapse>
                  </Card>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col className="error text-left mt-2 font-weight-bold">
                  {'Note: If you are booking a Video Consultation, ' +
                    'please ask the customer to book it on Cloudnine app.'}
                </Col>
              </Row>
            )}
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            CLOSE
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

BookingModal.propTypes = {
  doctorId: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  entityId: PropTypes.number,
  hospitalId: PropTypes.number,
  hospitalName: PropTypes.string
};
