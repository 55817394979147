import { Modal } from 'reactstrap';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MpidRegistration from './MpidRegistration';
import CustomerRegister from './CustomerRegister';

export default function RegistrationModal(props) {
  const [listPageEnabled, setListPageEnabled] = useState(true);
  const [registeredCustomers, setRegisteredCustomers] = useState([]);

  const toggleListPage = () => {
    setListPageEnabled(ps => !ps);
  };

  const updateRegisteredCustomers = customerList => {
    setRegisteredCustomers(customerList);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      size="xl"
      keyboard={false}
      backdrop="static"
    >
      {!listPageEnabled ? (
        <MpidRegistration
          toggle={props.toggle}
          toggleListPage={toggleListPage}
          selectedHospitalDetails={props.selectedHospitalDetails}
          registeredCustomers={registeredCustomers}
        />
      ) : (
        <CustomerRegister
          toggle={props.toggle}
          toggleListPage={toggleListPage}
          updateRegisteredCustomers={updateRegisteredCustomers}
        />
      )}
    </Modal>
  );
}

RegistrationModal.propTypes = {
  selectedHospitalDetails: PropTypes.object,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func
};
