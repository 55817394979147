import { ErrorMessage, Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Mutation, useQuery } from 'react-apollo';
import Select from 'react-select';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import {
  CREATE_HOSPITAL,
  GET_ALL_CHARGE_MASTERS,
  GET_ALL_ZOHO_ORGANIZATIONS,
  UPDATE_HOSPITAL_FULL,
  GET_ALL_SITECODES,
  GET_ALL_HOSPITALTYPES
} from '../queries';
import { displayError, displaySuccess, logAmplitudeEvent } from '../utilities';
import CitySelectorFormik from './CitySelectorFormik';
import SelectCloudnineHosp from './SelectCloudnineHosp';
import UploadLogo from './uploadLogo';

const enabledOpt = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];
const getValue = val => enabledOpt.find(({ value }) => value === val) || '';

const hospitalSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  address: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
  addressLine1: Yup.string()
    .min(1, 'Minimum length is one')
    .required('Required'),
  addressLine2: Yup.string()
    .min(3, 'Minimum length is three')
    .required('Required'),
  addressLine3: Yup.string(),
  lat: Yup.string()
    .test('len', 'Valid value between 7-12 digits', val => {
      const length = String(parseFloat(val)).length;
      return val && length >= 7 && length <= 12;
    })
    .required('Required'),
  lng: Yup.string()
    .test('len', 'Valid value between 7-12 digits', val => {
      const length = String(parseFloat(val)).length;
      return val && length >= 7 && length <= 12;
    })
    .required('Required'),
  cityId: Yup.string().required('Required'),
  pinCode: Yup.string().required('Required'),
  location: Yup.string().required('Required'),
  siteCode: Yup.string().required('Required'),
  isCloudnineHosp: Yup.bool(),
  isAppEnabled: Yup.bool(),
  isPharmaEnabled: Yup.bool(),
  isLabEnabled: Yup.bool(),
  isVaccinationEnabled: Yup.bool(),
  logoUrl: Yup.string(),
  callCentreNo: Yup.string(),
  appSupportEmail: Yup.string(),
  gstNo: Yup.string(),
  hospitalTypeId: Yup.number().required('Required'),
  chargeMaster: Yup.string().required('Required'),
  zohoOrgId: Yup.string().when('chargeMaster', {
    is: chargeMaster => chargeMaster === 'zoho_books',
    then: Yup.string().required('Required')
  }),
  settings: Yup.object().shape({
    chargeMaster: Yup.object().shape({
      invoiceGeneratorForConsultation: Yup.string()
    })
  })
});

const HospitalEditForm = ({ hospital, onSave, isNew }) => {
  const {
    data: { zohoOrganizations } = { zohoOrganizations: [] },
    loading: zohoOrganizationsLoading
  } = useQuery(GET_ALL_ZOHO_ORGANIZATIONS);

  const {
    data: { chargeMasters } = { chargeMasters: [] },
    loading: chargeMastersLoading
  } = useQuery(GET_ALL_CHARGE_MASTERS);

  const {
    data: { siteCodes } = { siteCodes: [] },
    loading: citiesLoading
  } = useQuery(GET_ALL_SITECODES);

  const {
    data: { getHospitalTypes } = { hospitalTypes: [] },
    loading: hospitalTypesLoading
  } = useQuery(GET_ALL_HOSPITALTYPES);

  const [checkLogoUrl, setCheckLogoUrl] = useState('');

  if (!hospital) {
    return <h3>Please select a hospital to edit</h3>;
  }

  const replaceNullWithEmptyString = obj => {
    if (Array.isArray(obj)) {
      return obj.map(replaceNullWithEmptyString);
    } else if (obj && typeof obj === 'object') {
      return Object.fromEntries(
        Object.entries(obj).map(([k, v]) => [
          k,
          replaceNullWithEmptyString(v !== null ? v : '')
        ])
      );
    }
    return obj !== undefined && obj !== null ? obj : '';
  };

  const initVal = replaceNullWithEmptyString(hospital);

  let id = hospital['id'] || '';
  let logoUrl = hospital['logoUrl'] || '';
  if (checkLogoUrl) {
    logoUrl = checkLogoUrl;
  }

  return (
    <div>
      <Mutation
        mutation={isNew ? CREATE_HOSPITAL : UPDATE_HOSPITAL_FULL}
        refetchQueries={() => ['GET_ALL_HOSPITALS_FULL']}
        onCompleted={() => {
          displaySuccess(
            isNew ? 'Hospital created successfully' : 'Successfully updated'
          );
          if (!isNew) {
            logAmplitudeEvent('Hospital_updated', hospital, true);
          }
        }}
        onError={e => displayError(e)}
      >
        {(updateCreateHospital, { loading }) => {
          return (
            <Formik
              initialValues={initVal}
              enableReinitialize
              validationSchema={hospitalSchema}
              onSubmit={async values => {
                const fields = {};
                const invoiceGeneratorForConsultation =
                  values.settings.chargeMaster.invoiceGeneratorForConsultation;

                // conditionally add only fields depending on full or partial update
                fields.name = values.name;
                fields.address = values.address;
                fields.addressLine1 = values.addressLine1;
                fields.addressLine2 = values.addressLine2;
                fields.addressLine3 = values.addressLine3;
                fields.lat = parseFloat(values.lat);
                fields.lng = parseFloat(values.lng);
                fields.cityId = values.cityId;
                fields.type = values.type;
                fields.departments = values.departments;
                fields.location = values.location;
                fields.pinCode = `${values.pinCode}`.trim();
                fields.services = values.services;
                fields.beds =
                  `${values.beds}`.trim().length > 0
                    ? parseInt(`${values.beds}`.trim(), 10)
                    : 0;
                fields.siteCode = values.siteCode;
                fields.fertilitySiteCode = values.fertilitySiteCode;
                fields.seoSlug = values.seoSlug;
                fields.isCloudnineHosp = Boolean(values.isCloudnineHosp);
                fields.isAppEnabled = values.isAppEnabled;
                fields.isPharmaEnabled = values.isPharmaEnabled;
                fields.isLabEnabled = values.isLabEnabled;
                fields.isVaccinationEnabled = values.isVaccinationEnabled;
                fields.logoUrl = logoUrl || '';
                fields.callCentreNo = values.callCentreNo;
                fields.appSupportEmail = values.appSupportEmail;
                fields.gstNo = values.gstNo;
                fields.chargeMaster = values.chargeMaster;
                fields.zohoOrgId = values.zohoOrgId || '';
                fields.hospitalTypeId = values.hospitalTypeId;
                if (invoiceGeneratorForConsultation) {
                  fields.settings = {
                    chargeMaster: {
                      invoiceGeneratorForConsultation
                    }
                  };
                }

                if (!isNew) {
                  const savedH = await updateCreateHospital({
                    variables: {
                      hospitalId: hospital.id,
                      hospital: fields
                    }
                  });
                  onSave(savedH.data.hospital);
                } else {
                  const savedH = await updateCreateHospital({
                    variables: { hospital: fields }
                  });
                  onSave(savedH.data.hospital);
                }
                setCheckLogoUrl('');
              }}
              render={({ resetForm, values, handleSubmit }) => {
                return (
                  <div className="hospital-form">
                    <Row>
                      <Col md="2">
                        <label>Name</label>
                      </Col>
                      <Col>
                        <Field type="text" name="name" />
                        <ErrorMessage
                          component="span"
                          name="name"
                          className="error"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Address</label>
                      </Col>
                      <Col>
                        <Field type="text" name="address" />
                        <ErrorMessage
                          component="span"
                          name="address"
                          className="error"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Address Line 1</label>
                      </Col>
                      <Col>
                        <Field type="text" name="addressLine1" />
                        <ErrorMessage
                          component="span"
                          name="addressLine1"
                          className="error"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Address Line 2</label>
                      </Col>
                      <Col>
                        <Field type="text" name="addressLine2" />
                        <ErrorMessage
                          component="span"
                          name="addressLine2"
                          className="error"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Address Line 3</label>
                      </Col>
                      <Col>
                        <Field type="text" name="addressLine3" />
                        <ErrorMessage
                          component="span"
                          name="addressLine3"
                          className="error"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Latitude</label>
                      </Col>
                      <Col>
                        <Field type="text" name="lat" />
                        <ErrorMessage
                          component="span"
                          name="lat"
                          className="error"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Longitude</label>
                      </Col>
                      <Col>
                        <Field type="text" name="lng" />
                        <ErrorMessage
                          component="span"
                          name="lng"
                          className="error"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>City</label>
                      </Col>
                      <Col>
                        <Field
                          type="text"
                          name="cityId"
                          component={CitySelectorFormik}
                        />
                        <ErrorMessage
                          component="span"
                          name="cityId"
                          className="error"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Type</label>
                      </Col>
                      <Col>
                        <Field type="text" name="type" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Departments</label>
                      </Col>
                      <Col>
                        <Field type="text" name="departments" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Location</label>
                      </Col>
                      <Col>
                        <Field type="text" name="location" />
                        <ErrorMessage
                          component="span"
                          name="location"
                          className="error"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Pin Code</label>
                      </Col>
                      <Col>
                        <Field type="number" name="pinCode" />
                        <ErrorMessage
                          component="span"
                          name="pinCode"
                          className="error"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Zone</label>
                      </Col>
                      <Col>
                        <Field disabled type="text" name="zone" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Services</label>
                      </Col>
                      <Col>
                        <Field type="text" name="services" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Beds</label>
                      </Col>
                      <Col>
                        <Field type="number" name="beds" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Site Code</label>
                      </Col>
                      <Col>
                        <Field
                          type="text"
                          name="siteCode"
                          component={({ field, form }) => {
                            const { value: selectedSiteCode, name } = field;
                            const value = !selectedSiteCode
                              ? ''
                              : (siteCodes || []).find(
                                  ({ sitecode }) =>
                                    sitecode === selectedSiteCode
                                ) || {
                                  sitecode: selectedSiteCode,
                                  id: selectedSiteCode
                                };
                            return (
                              <Select
                                value={value}
                                options={siteCodes || []}
                                isLoading={citiesLoading}
                                getOptionValue={({ id }) => id}
                                getOptionLabel={({ sitecode }) => sitecode}
                                onChange={v => {
                                  form.setFieldValue(name, v.sitecode);
                                }}
                              />
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Fertility Site Code</label>
                      </Col>
                      <Col>
                        <Field type="text" name="fertilitySiteCode" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>SEO Slug</label>
                      </Col>
                      <Col>
                        <Field type="text" name="seoSlug" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Hospital Type</label>
                      </Col>
                      <Col>
                        <Field
                          type="text"
                          name="hospitalTypeId"
                          component={({ field, form }) => {
                            const { value: selectedHospitalType, name } = field;
                            const value = selectedHospitalType
                              ? (getHospitalTypes || []).find(
                                  ({ id }) => selectedHospitalType === id
                                )
                              : '';
                            return (
                              <Select
                                value={value}
                                options={getHospitalTypes || []}
                                isLoading={hospitalTypesLoading}
                                getOptionValue={({ id }) => id}
                                getOptionLabel={({ name }) => name}
                                onChange={v => {
                                  form.setFieldValue(name, v.id);
                                }}
                              />
                            );
                          }}
                        />
                        <ErrorMessage
                          component="span"
                          name="hospitalTypeId"
                          className="error"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Is Cloudnine Hospital</label>
                      </Col>
                      <Col>
                        <Field
                          name="isCloudnineHosp"
                          render={({ form, field }) => {
                            return (
                              <SelectCloudnineHosp
                                selectedCloudnine={
                                  field.value ? field.value : false
                                }
                                onCloudnineSelect={v =>
                                  form.setFieldValue(field.name, v)
                                }
                              />
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Is App Enabled</label>
                      </Col>
                      <Col>
                        <Field
                          name="isAppEnabled"
                          render={({ form, field }) => {
                            return (
                              <Select
                                value={getValue(!!field.value)}
                                onChange={val => {
                                  form.setFieldValue(field.name, val.value);
                                }}
                                options={enabledOpt}
                              />
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Is Homeservice Pharmacy Enabled</label>
                      </Col>
                      <Col>
                        <Field
                          name="isPharmaEnabled"
                          render={({ form, field }) => {
                            return (
                              <Select
                                value={getValue(!!field.value)}
                                onChange={val => {
                                  form.setFieldValue(field.name, val.value);
                                }}
                                options={enabledOpt}
                              />
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Is Homeservice Lab Enabled</label>
                      </Col>
                      <Col>
                        <Field
                          name="isLabEnabled"
                          render={({ form, field }) => {
                            return (
                              <Select
                                value={getValue(!!field.value)}
                                onChange={val => {
                                  form.setFieldValue(field.name, val.value);
                                }}
                                options={enabledOpt}
                              />
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Is Homeservice Vaccination Enabled</label>
                      </Col>
                      <Col>
                        <Field
                          name="isVaccinationEnabled"
                          render={({ form, field }) => {
                            return (
                              <Select
                                value={getValue(!!field.value)}
                                onChange={val => {
                                  form.setFieldValue(field.name, val.value);
                                }}
                                options={enabledOpt}
                              />
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Logo URL</label>
                      </Col>
                      <Col>
                        <UploadLogo
                          url={logoUrl}
                          hospitalId={id}
                          onlogoUpload={v => {
                            setCheckLogoUrl(v);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Call Centre Number</label>
                      </Col>
                      <Col>
                        <Field type="text" name="callCentreNo" />
                        <ErrorMessage
                          component="span"
                          name="callCentreNo"
                          className="error"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>AppSupport Email Id</label>
                      </Col>
                      <Col>
                        <Field type="text" name="appSupportEmail" />
                        <ErrorMessage
                          component="span"
                          name="appSupportEmail"
                          className="error"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>GST No.</label>
                      </Col>
                      <Col>
                        <Field type="text" name="gstNo" />
                        <ErrorMessage
                          component="span"
                          name="gstNo"
                          className="error"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Charge Master</label>
                      </Col>
                      <Col>
                        <Field
                          type="text"
                          name="chargeMaster"
                          component={({ field, form }) => {
                            const { value: chargeMasterVal, name } = field;
                            const opts = chargeMasters.map(
                              ({ name, value }) => ({
                                value: value,
                                label: name
                              })
                            );

                            const value = opts.find(
                              opt => opt.value === chargeMasterVal
                            );

                            return (
                              <Select
                                value={value}
                                options={opts}
                                isLoading={chargeMastersLoading}
                                onChange={v => {
                                  form.setFieldValue(name, v.value);
                                  form.setFieldValue('zohoOrgId', '');
                                }}
                              />
                            );
                          }}
                        />
                        <ErrorMessage
                          component="span"
                          name="chargeMaster"
                          className="error"
                        />
                      </Col>
                    </Row>
                    {values.chargeMaster === 'zoho_books' ? (
                      <Row>
                        <Col md="2">
                          <label>Zoho Organisation</label>
                        </Col>
                        <Col>
                          <Field
                            type="text"
                            name="zohoOrgId"
                            component={({ field, form }) => {
                              const { value: zohoOrgVal, name } = field;
                              const opts = zohoOrganizations.map(
                                ({ name, id }) => ({
                                  value: id,
                                  label: name
                                })
                              );

                              const value = opts.find(
                                opt => opt.value === zohoOrgVal
                              );

                              return (
                                <Select
                                  value={value}
                                  options={opts}
                                  isLoading={zohoOrganizationsLoading}
                                  onChange={v => {
                                    form.setFieldValue(name, v.value);
                                  }}
                                />
                              );
                            }}
                          />
                          <ErrorMessage
                            component="span"
                            name="zohoOrgId"
                            className="error"
                          />
                        </Col>
                      </Row>
                    ) : null}
                    <Row className="mb-2">
                      <Col>
                        <h5>Settings:</h5>
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col>
                        <h6>Charge Master:</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>Invoice generator for consultation</label>
                      </Col>
                      <Col>
                        <Field
                          type="text"
                          name="settings.chargeMaster.invoiceGeneratorForConsultation"
                          component={({ field, form }) => {
                            const { value: zohoOrgVal, name } = field;
                            const opts = [
                              {
                                label: 'Users service',
                                value: 'users_service'
                              },
                              {
                                label: 'Users service and Zoho books',
                                value: 'users_service_and_zoho_books'
                              }
                            ];

                            const value = opts.find(
                              opt => opt.value === zohoOrgVal
                            );

                            return (
                              <Select
                                value={value}
                                options={opts}
                                isLoading={false}
                                onChange={v => {
                                  form.setFieldValue(name, v.value);
                                }}
                              />
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="modal-footer">
                          <Button
                            type="button"
                            color="primary"
                            onClick={() => {
                              resetForm();
                              logAmplitudeEvent(
                                'Hospital_Form_Reset',
                                {},
                                true
                              );
                            }}
                            disabled={loading}
                          >
                            RESET
                          </Button>{' '}
                          <Button
                            type="button"
                            color="primary"
                            disabled={loading}
                            onClick={handleSubmit}
                          >
                            SAVE
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              }}
            />
          );
        }}
      </Mutation>
    </div>
  );
};

HospitalEditForm.propTypes = {
  hospital: PropTypes.object,
  onSave: PropTypes.func,
  isNew: PropTypes.bool
};
HospitalEditForm.defaultProps = {
  onSave: () => {},
  isNew: false
};

export default HospitalEditForm;
