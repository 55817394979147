import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Query } from 'react-apollo';
import DatePicker from 'react-datepicker';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { Button, Col, Collapse, Container, Row, Table } from 'reactstrap';
import Loader from '../components/Loader';
import { GET_AUDIT_LOGS_IN_DATE_RANGE } from '../queries';
import { handleExportCSV } from '../utilities/auditLogs';
import Pagination from './Pagination';

const auditsQuery = {
  startDate: moment()
    .startOf('day')
    .toDate(),
  endDate: moment()
    .endOf('day')
    .toDate()
};

const AuditLogsTab = () => {
  const [auditQuery, setAuditQuery] = useState(auditsQuery);
  const [selectedAuditRow, setSelectedAuditRow] = useState(null);
  const [selectedTargetType, setSelectedTargetType] = useState('ALL');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const handleAuditRowClick = id => {
    if (selectedAuditRow === id) {
      setSelectedAuditRow(null);
    } else {
      setSelectedAuditRow(id);
    }
  };

  const isDateRangeValid = (startDate, endDate) => {
    const maxEndDate = moment(startDate).add(7, 'days');
    return moment(endDate).isSameOrBefore(maxEndDate, 'day');
  };

  const handleStartDateChange = date => {
    const startDate = moment(date)
      .startOf('day')
      .toDate();
    const endDate = moment(auditQuery.endDate);
    let maxEndDate = moment(startDate)
      .add(7, 'days')
      .endOf('day')
      .toDate();

    // Check if the calculated maxEndDate is after the current date
    const currentDate = moment()
      .endOf('day')
      .toDate();
    if (moment(maxEndDate).isAfter(currentDate)) {
      maxEndDate = currentDate;
    }

    if (isDateRangeValid(startDate, endDate)) {
      if (moment(startDate).isAfter(moment(endDate))) {
        setAuditQuery({ startDate, endDate: maxEndDate });
      } else {
        setAuditQuery({ ...auditQuery, startDate });
      }
    } else {
      // Adjust end date if the range exceeds 7 days
      setAuditQuery({
        startDate,
        endDate: maxEndDate
      });
    }
  };

  const handleEndDateChange = date => {
    const endDate = moment(date)
      .endOf('day')
      .toDate();
    const startDate = moment(auditQuery.startDate);

    if (isDateRangeValid(startDate, endDate)) {
      setAuditQuery({ ...auditQuery, endDate });
    } else {
      setAuditQuery({
        ...auditQuery,
        endDate: moment(startDate)
          .add(7, 'days')
          .endOf('day')
          .toDate()
      });
    }
  };

  useEffect(() => {
    setSelectedAuditRow(null);
  }, [auditQuery, selectedTargetType, searchTerm]);

  return (
    <Container style={{ margin: '20px' }}>
      <div>
        <Row style={{ marginBottom: '2rem' }}>
          <Col md={3}>
            <React.Fragment>
              <Row>
                <Col sm={6}>From</Col>
                <Col sm={6}>To</Col>
              </Row>
              <Row>
                <Col sm={6} className="form-row">
                  <DatePicker
                    selected={moment(auditQuery.startDate)}
                    maxDate={moment()}
                    dateFormat="DD MMM YYYY"
                    selectsStart
                    startDate={moment(auditQuery.startDate)}
                    endDate={moment(auditQuery.endDate)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={handleStartDateChange}
                  />
                </Col>
                <Col sm={6} className="form-row">
                  <DatePicker
                    selected={moment(auditQuery.endDate)}
                    maxDate={moment()}
                    selectsEnd
                    startDate={moment(auditQuery.startDate)}
                    endDate={moment(auditQuery.endDate)}
                    minDate={moment(auditQuery.startDate)}
                    dateFormat="DD MMM YYYY"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={handleEndDateChange}
                  />
                </Col>
              </Row>
            </React.Fragment>
          </Col>
          <Col md={3}>
            <Row>
              <Col sm={6}>Target Type</Col>
            </Row>
            <Row>
              <Col sm={12}>
                <select
                  value={selectedTargetType}
                  onChange={e => setSelectedTargetType(e.target.value)}
                >
                  <option value={'ALL'}>All</option>
                  <option value={'DOCTOR PROFILE'}>Doctor Profile</option>
                  <option value={'VACATION'}>Vacation</option>
                  <option value={'BOOKING'}>Bookings</option>
                  <option value={'MODIFY_SCHEDULE'}>Schedule</option>
                  <option value={'SLOT'}>Slots</option>
                  <option value={'ADMIN USER'}>Admin users</option>
                  <option value={'HOSPITAL'}>Hospitals</option>
                </select>
              </Col>
            </Row>
          </Col>
          <Col md={2}>
            <input
              type="text"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              placeholder="Search anything..."
            />
          </Col>
        </Row>
        <Row>
          <Query
            query={GET_AUDIT_LOGS_IN_DATE_RANGE}
            variables={{ input: auditQuery }}
            fetchPolicy="network-only"
            pollInterval={10000}
          >
            {({ loading, error, data }) => {
              if (loading) return <Loader />;

              if (error) return <span>Something went wrong.{error}</span>;
              const auditLogs = data.auditLogs;
              // eslint-disable-next-line
              const filteredAuditLogs = auditLogs.filter(log => {
                const logValues = [
                  moment(log.created_at).format('DD MMM YYYY HH:mm'),
                  log.targetName,
                  log.targetType,
                  log.action,
                  log.userName
                ]
                  .join(' ')
                  .toLowerCase();

                return (
                  (selectedTargetType === 'ALL' ||
                    log.targetType === selectedTargetType) &&
                  logValues.includes(searchTerm.toLowerCase())
                );
              });

              const totalRows = filteredAuditLogs.length;
              const pageCount = Math.ceil(totalRows / rowsPerPage);
              const startIndex = (currentPage - 1) * rowsPerPage;
              const paginatedLogs = filteredAuditLogs.slice(
                startIndex,
                startIndex + rowsPerPage
              );

              if (!filteredAuditLogs || filteredAuditLogs.length < 1) {
                return <span>{"That's all, folks!"}</span>;
              }

              return (
                <>
                  <Button
                    style={{
                      color: 'black',
                      backgroundColor: 'khaki',
                      fontWeight: 'bold',
                      marginBottom: '1rem',
                      marginLeft: '72rem'
                    }}
                    size="sm"
                    onClick={() => handleExportCSV(auditLogs)}
                  >
                    Export as CSV
                  </Button>
                  <div style={{ width: '100%' }}>
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th></th>
                          <th>TIMESTAMP</th>
                          <th>TARGET</th>
                          <th>TARGET TYPE</th>
                          <th>ACTION</th>
                          <th>DONE BY</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedLogs.map(log => {
                          const {
                            created_at,
                            targetName,
                            targetType,
                            action,
                            userName,
                            meta,
                            id
                          } = log;
                          return (
                            <>
                              <tr
                                key={`${id}-title`}
                                onClick={() => handleAuditRowClick(id)}
                              >
                                <td>
                                  {selectedAuditRow === id ? (
                                    <IoIosArrowUp />
                                  ) : (
                                    <IoIosArrowDown />
                                  )}{' '}
                                </td>
                                <td>
                                  {moment(created_at).format(
                                    'DD MMM YYYY HH:mm'
                                  )}
                                </td>
                                <td>{targetName}</td>
                                <td>{targetType}</td>
                                <td>{action.toUpperCase()}</td>
                                <td>{userName}</td>
                              </tr>
                              {selectedAuditRow === id && (
                                <tr key={`${id}-meta`}>
                                  <td colSpan={7}>
                                    <Collapse isOpen={selectedAuditRow === id}>
                                      <pre
                                        style={{
                                          overflow: 'auto',
                                          wordWrap: 'normal',
                                          width: '1100px'
                                        }}
                                      >
                                        {JSON.stringify(meta, null, 2)}
                                      </pre>
                                    </Collapse>
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                  <div className="pagination-container">
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={handlePageChange}
                      totalPages={pageCount}
                    />
                  </div>
                </>
              );
            }}
          </Query>
        </Row>
      </div>
    </Container>
  );
};
export default AuditLogsTab;
