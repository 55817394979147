import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-apollo';
import { FaUserMd } from 'react-icons/fa';
import { FixedSizeList as List } from 'react-window';
import { displayError } from '../utilities';
import DocBadge from './DocBadge';
import Loader from './Loader';
import { Button } from 'reactstrap';
import { IoMdRefresh } from 'react-icons/io';
import { getLoggedInUser } from '../permissions';

const RenderDoctorList = ({
  query,
  variables,
  filterFunction,
  onDoctorClick
}) => {
  const user = getLoggedInUser();
  const { loading, error, data, refetch, networkStatus } = useQuery(query, {
    variables,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  if (loading || networkStatus === 4) {
    return <Loader />;
  }
  if (error) {
    displayError(error);
    return (
      <div className="muted-text doc-not-found">
        <h4>
          <FaUserMd /> No doctor found
        </h4>
      </div>
    );
  }

  const docs = data && data.getDoctors ? data.getDoctors : [];
  if (docs.length < 1) {
    return (
      <div className="muted-text doc-not-found">
        <h4>
          <FaUserMd /> No doctor found
        </h4>
      </div>
    );
  }

  const renderDocs = filterFunction ? docs.filter(filterFunction) : docs;

  return (
    <div>
      <Button color="link" onClick={() => refetch()}>
        <IoMdRefresh size={20} />
      </Button>

      <List
        itemData={renderDocs}
        height={800}
        itemSize={80}
        itemCount={renderDocs.length}
      >
        {({ index, data, style }) => (
          <div style={{ ...style, paddingRight: '5px' }}>
            <DocBadge
              doc={data[index]}
              role={user.role}
              onClick={e => {
                e.preventDefault();
                onDoctorClick(data[index].id);
              }}
            />
          </div>
        )}
      </List>
    </div>
  );
};

RenderDoctorList.propTypes = {
  query: PropTypes.object.isRequired,
  variables: PropTypes.object.isRequired,
  filterFunction: PropTypes.func,
  onDoctorClick: PropTypes.func.isRequired
};

export default RenderDoctorList;
